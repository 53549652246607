import { useFormik } from "formik";

import { useCallback } from "react";
import * as yup from "yup";

import s from "./GeneralOnboarding.module.css";

import cn from "classnames";

import { Accordion, Button, ErrorField, Htag } from "components";

import { useAppDispatch, useAppSelector } from "utils/hooks";
import { QuestionnaireProps } from './Questionnaire.props';

export const validationSignupSchema = yup.object({
  years_of_marriage: yup.number().required("Please select an option."),
  years_before_marriage: yup.number().required("Please select an option."),
});

const desc = `<p>Your answer to this question is important for two reasons.</p>
    <p>
      Firstly, if you’ve been married for less than a year you won’t be able to
      get a divorce yet. If this applies to you, we’ll give you some information
      about your options.
    </p>
    <p>
      Secondly, the length of your marriage is relevant to what a fair financial
      outcome might look like. If you lived together as a couple immediately
      before you got married, that time will almost certainly be counted towards
      the length of your marriage when working out how to divide your finances
      fairly.
    </p>`;

interface IInputProp {
  onChange: (ev: React.ChangeEvent<HTMLInputElement>) => void;
  type: string;
  name: string;
  placeholder: string;
  onBlur: {
    (e: React.FocusEvent<any, Element>): void;
    <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
  };
  min: number;
  step: number;
  value: any;
}

export const FormGB1 = ({
  handleBack,
  setData,
  handleNextStep,
}: QuestionnaireProps): JSX.Element => {
  const { generalOnboarding, activeStep } = useAppSelector(
    (state) => state.generalOnBoardingState
  );

  const dispatch = useAppDispatch();

  const getInputProps = (name: string): IInputProp => ({
    onChange: updateInterview,
    type: "number",
    name,
    placeholder:
      name === "years_of_marriage"
        ? "Years of marriage"
        : "Years before marriage",
    onBlur: formik.handleBlur,
    min: 0,
    step: 1,
    value: formik.values[name as keyof typeof generalOnboarding],
  });

  const formik = useFormik({
    initialValues: generalOnboarding,
    validationSchema: validationSignupSchema,
    onSubmit: () => {
      handleNextStep();
    },
  });

  const updateInterview = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = ev.target;
      formik.handleChange(ev);
      setData(name, value);
    },
    [dispatch, formik, generalOnboarding]
  );

  return (
    <form id="form-1" onSubmit={formik.handleSubmit} className={s.form}>
      <span className={s.stepMark}>{`${activeStep} `}</span>
      <Htag tag="h3" className='mb10'>
        How many years have you and your ex been married for? If you’ve been
        married for less than a year, please say ‘0’.
      </Htag>
      <div className={cn(s.formField, s.form_fullField)}>
        <input {...getInputProps("years_of_marriage")} />
        {formik.touched.years_of_marriage &&
          formik.errors.years_of_marriage && (
            <ErrorField error={formik.errors.years_of_marriage} />
          )}
      </div>

      <Htag tag="h3" className='mb10'>
        If you lived together before you got married, how many years was that
        for?
      </Htag>
      <div className={cn(s.formField, s.form_fullField)}>
        <input {...getInputProps("years_before_marriage")} />
        {formik.touched.years_before_marriage &&
          formik.errors.years_before_marriage && (
            <ErrorField error={formik.errors.years_before_marriage} />
          )}
      </div>

      <Accordion
        className={s.desc}
        accordionData={[
          {
            title: `Why are we asking?`,
            content: desc,
          },
        ]}
      />

      <div className={s.groupButton}>
        <Button
          onClick={() => handleBack()}
          apearance="default"
          className={s.goBack}
          type="button"
        >
          Previous
        </Button>
        <Button type="submit" apearance="secondary">
          Next
        </Button>
      </div>
    </form>
  );
};
