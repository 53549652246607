import { createAsyncThunk } from "@reduxjs/toolkit";
import { IInitState } from "../root-interface";
import {
  GET_ALL_DIVORCE_STEPS,
  GET_DIVORCE_STEP,
  SET_TASK_STATUS,
} from "../config";
import { IDivorceStep } from "./divorceSteps.interface";
import { ITask } from "../tasks/tasks-interface";
import { API_TYPE, getRequestFabric } from "../thunk-helper";

// Load data for 6 steps

export const loadDivorceSteps = createAsyncThunk<
  IDivorceStep[],
  undefined,
  { getState: IInitState; rejectValue: string }
>(
  "@@divorceSteps/divorceSteps",
  async function (_, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.GET,
      getState,
      GET_ALL_DIVORCE_STEPS(),
      rejectWithValue,
      dispatch
    );
  }
);

// Load single step
export const loadDivorceStep = createAsyncThunk<
  IDivorceStep,
  number,
  { getState: IInitState; rejectValue: string }
>(
  "@@divorceSteps/divorceStep",
  async function (id, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.GET,
      getState,
      GET_DIVORCE_STEP(id),
      rejectWithValue,
      dispatch
    );
  }
);

export const setNewTaskStatus = createAsyncThunk<
  ITask,
  { task_id: number | undefined; status: number; sort: number | undefined },
  { getState: IInitState; rejectValue: string }
>(
  "@@divorceSteps/setTaskStatus",
  async function (
    { task_id, status, sort },
    { getState, rejectWithValue, dispatch }
  ) {
    const data = JSON.stringify({ status: Number(status), sort: sort });
    
    return (await getRequestFabric(
      API_TYPE.PUT,
      getState,
      SET_TASK_STATUS(task_id),
      rejectWithValue,
      dispatch,
      data
    )) as ITask;
  }
);
