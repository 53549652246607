import { useEffect, useState } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
} from "@mui/material";
import { NavLink, useLocation } from "react-router-dom";
import { Delete, Edit } from "@mui/icons-material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import s from "../SpecialSources.module.css";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { Button, Htag } from "components";

import { Post } from "store/blog/blog.interfaces";
import { deletePost, get_all_posts } from "store/blog/blog-thunk";
import dayjs from "dayjs";

interface deleteObj {
  slug: string;
  delFunc: (slug: string) => void;
}

export const BlogList = () => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [checkedItem, setCheckedItem] = useState<deleteObj | null>(null);

  const posts = useAppSelector((state) => state.blogPostsState.posts);

  const [pageState, setPageState] = useState<{
    data: Post[];
    total: number;
  }>({
    data: [],
    total: 0,
  });

    const [paginationModel, setPaginationModel] = useState({
      page: 0,
      pageSize: 15,
    });

  const handleClose = () => {
    setOpenDeleteDialog(false);
    setCheckedItem(null);
  };

  const copy = async (slug: string) => {
    await navigator.clipboard.writeText(
      `${process.env.REACT_APP_BASE_URL}/blog/${slug}`
    );
  };

  useEffect(() => {
    dispatch(
      get_all_posts({
        page: paginationModel.page,
        limit: paginationModel.pageSize,
      })
    )
      .unwrap()
      .then((resp) => {
        setPageState((old) => ({
          ...old,
          data: resp.data,
          total: resp.meta.total,
        }));
      });
  }, [dispatch, paginationModel.page, paginationModel.pageSize]);

  const confirmDelete = () => {
    checkedItem?.delFunc(checkedItem.slug);
    setCheckedItem(null);
    setOpenDeleteDialog(false);
  };

  const handleDeleteGuide = (slug: string) => {
    if (!slug) return;
    dispatch(deletePost(slug));
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      headerClassName: s.headerClassName,
      width: 70,
      sortable: false,
      disableColumnMenu: true,
    },

    {
      field: "title",
      headerClassName: s.headerClassName,
      headerName: "Title",
      sortable: true,
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: "author",
      headerName: "Author",
      headerClassName: s.headerClassName,
      flex: 1,
      sortable: true,
      disableColumnMenu: true,
    },
    {
      field: "content",
      headerName: "Content",
      headerClassName: s.headerClassName,
      flex: 1,
      sortable: true,
      disableColumnMenu: true,
    },
    {
      field: "created_at",
      headerName: "Create Date",
      headerClassName: s.headerClassName,
      flex: 1,
      sortable: true,
      disableColumnMenu: true,
      renderCell: (params) => {
        return <>{dayjs(params.row.created_at).format("MMMM DD, YYYY")}</>;
      },
    },
    {
      field: "action",
      headerName: `Action`,
      headerClassName: s.headerClassName,
      sortable: false,
      width: 150,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <div className={s.actions}>
            <NavLink to={`${pathname}/edit-page/${params.row.slug}`}>
              <IconButton aria-label="edit" color="info">
                <Edit />
              </IconButton>
            </NavLink>

            <ContentCopyIcon
              className={s.externalLink}
              onClick={() => copy(params.row.slug)}
            />
            <IconButton
              aria-label="delete"
              color="error"
              onClick={(e) => {
                e.stopPropagation();
                // handleDeleteTool(Number(params.id));
                setCheckedItem({
                  slug: params.row.slug,
                  delFunc: handleDeleteGuide,
                });
                setOpenDeleteDialog(true);
              }}
            >
              <Delete />
            </IconButton>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Htag tag="h3">List of blog posts</Htag>
      <Box>
        <DataGrid
          paginationMode="server"
          rows={posts}
          autoHeight
          rowCount={pageState.total}
          pagination
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { pageSize: 10, page: 0 },
            },
          }}
          className={s.tableWrap}
          pageSizeOptions={[10, 30, 50, 70, 100]}
          onPaginationModelChange={setPaginationModel}
          //   onPageChange={(newPage) => {
          //     setPageState((old) => ({ ...old, page: newPage + 1 }));
          //   }}
          //   onPageSizeChange={(newPageSize) =>
          //     setPageState((old) => ({ ...old, pageSize: newPageSize }))
          //   }
        />
      </Box>
      <Dialog
        open={openDeleteDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={s.deleteConfirmDialog}
      >
        <DialogContent className={s.deleteConfirmText}>
          Please, confirm deleting
        </DialogContent>
        <DialogActions className={s.deleteConfirmBtn}>
          <Button apearance="secondaryDark" onClick={handleClose}>
            NO
          </Button>
          <Button apearance="secondaryDark" onClick={confirmDelete}>
            AGREE
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
