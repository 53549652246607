import { useParams } from "react-router-dom";

import { ArticleContent } from "../../../../components/ArticleContent/ArticleContent.component";
import { useAppDispatch, useAppSelector } from "../../../../utils/hooks";
import { useEffect, useState } from "react";

import s from "./AddWGuide.module.css";

import { loadSingleGuide } from "store/wellbeing/wellbeing-thunk";
import { IGuide } from "store/system_guides/system_guide.interface";

export const PreviewWSource = () => {
  const { id } = useParams();

  const [type, setType] = useState("");
  const dispatch = useAppDispatch();

  const href = window.location.pathname;

  const item: IGuide = useAppSelector((state) => {
    if (href.includes("sguide")) {
      return state.wellbeing.system_guides.filter(
        (guide: IGuide) => guide.id == id
      )[0];
    } else {
      return state.wellbeing.guides.filter((item: IGuide) => item.id == id)[0];
    }
  });

  useEffect(() => {
    if (!id || (item && item.content)) return;

    dispatch(loadSingleGuide(Number(id)));
  }, []);

  return (
    <div className={s.article}>
      {item && <ArticleContent data={item} type="guide" />}
    </div>
  );
};
