import s from "./GeneralOnboarding.module.css";

import { FormControlLabel, Radio, RadioGroup } from "@mui/material";

import { useCallback } from "react";

import questionnaire from "./questionnaire.json";
import cn from "classnames";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { resetTasksIds } from "store/system_tasks/system_tasks-reducer";
import { resetGuidesIds } from "store/system_guides/system_guide-reducer";

import { add_system_tasks } from "store/system_tasks/system-tasks-thunk";

import { add_guides_to_dashboard } from "store/system_guides/system_guide-thunk";
import { Accordion, Button, ErrorField, Htag } from "components";
import * as yup from "yup";
import { useFormik } from "formik";
import { saveGOnboarding } from "store/generalOnboarding/generalOnboarding-thunk";
import { sortArray } from "utils/utils";
import { add_case_studies } from "store/caseStudy/caseStudy_thunk";
import { getUserData } from "store/userInfo/user-thunk";
import { add_wsystem_guides } from "store/wellbeing/wsystem_guides.thunk";
import { resetCSIds } from "store/caseStudy/caseStudy.reducer";
import { QuestionnaireProps } from "./Questionnaire.props";
const validationForm13 = yup.object({
  nuptial_agreement: yup
    .string()
    .nullable()
    .required("Please select an option."),
});

const answers = [
  {
    title: "What is a pre-nuptial agreement?",
    content:
      "A pre-nuptial agreement (sometimes called a ‘pre-nup’) is a contract that a couple enters into before they get married (or enter into a civil partnership).  The contract explains how the couple will divide their finances if their relationship ends.",
  },
  {
    title: "What is a post-nuptual agreement?",
    content:
      "A post-nuptial agreement (sometimes called a ‘post-nup’) is the same as a pre-nup except that is entered into by the couple after they are married (or civil partnered).  So, in short, it’s a formal agreement (a contract) to record how the couple will divide their finances if their relationship ends.",
  },
  {
    title: "Why are we asking this?",
    content:
      "If you have a pre-nuptial or post-nuptial agreement it is likely to impact the financial outcome of your divorce.  If this is relevant to you, we’ll explain what factors (such as each taking independent legal advice at the time) may affect the weight a court would give to the agreement. ",
  },
];

function collectResults(userAnswers: any, questions: any) {
  const tasks = new Set<string>();
  const guides = new Set<string>();

  questions.forEach((question: any) => {
    const fieldName = question.field_name;

    if (fieldName === "debts") {
      userAnswers.debts.forEach((debt: any) => {
        if (debt.value) {
          const debtKey = debt.name;
          const debtAnswers = question.answers[debtKey];
          if (debtAnswers) {
            debtAnswers.tasks.forEach((task: any) => tasks.add(task));
            debtAnswers.guides.forEach((guide: any) => guides.add(guide));
          }
        }
      });
    } else {
      // Handle single-value answers

      const userAnswer = userAnswers[fieldName];
      if (userAnswer && question.answers[userAnswer]) {
        const answerData = question.answers[userAnswer];
        answerData.tasks.forEach((task: any) => tasks.add(task));
        answerData.guides.forEach((guide: any) => guides.add(guide));
      }
    }
  });

  return {
    tasks: Array.from(tasks),
    guides: Array.from(guides),
  };
}

export const FormGB13 = ({
  handleBack,
  handleNextStep,
  setData,
}: QuestionnaireProps): JSX.Element => {
  const { generalOnboarding, activeStep, loading } = useAppSelector(
    (state) => state.generalOnBoardingState
  );

  const parenting_tasks_id = useAppSelector(
    (state) => state.specialTasksState.tasks
  );

  const case_studies_id = useAppSelector(
    (state) => state.caseStudiesState.caseStudies_db
  );

  const parenting_guides_id = useAppSelector(
    (state) => state.specialGuidesState.guides
  );
  const system_wguides_id = useAppSelector(
    (state) => state.wellbeing.system_guides_id
  );

  const guides_for_all =
    questionnaire[questionnaire.length - 1].answers.yes?.guides || [];
  const tasks_for_all =
    questionnaire[questionnaire.length - 1].answers.yes?.tasks || [];

  const loadingTasks = useAppSelector(
    (state) => state.specialTasksState.loading
  );
  const loadingGuides = useAppSelector(
    (state) => state.specialGuidesState.loading
  );

  const dispatch = useAppDispatch();

  const updateInterview = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = ev.target;
      formik.handleChange(ev);
      setData(name, value);
    },
    [dispatch, generalOnboarding]
  );

  const formik = useFormik({
    initialValues: generalOnboarding,
    validationSchema: validationForm13,
    onSubmit: () => {
      const results = collectResults(generalOnboarding, questionnaire);

      dispatch(saveGOnboarding(generalOnboarding))
        .unwrap()
        .then(() => {
          if (generalOnboarding.has_children == "no") {
            if (generalOnboarding.home_property == "rented") {
              dispatch(add_case_studies(sortArray(["F-CS-1", "F-CS-2"])));
            } else if (generalOnboarding.home_property == "owned") {
              dispatch(add_case_studies(sortArray(["F-CS-3", "F-CS-4"])));
            }
          }
          dispatch(
            add_system_tasks(
              sortArray([
                ...results.tasks,
                ...parenting_tasks_id,
                ...tasks_for_all,
              ])
            )
          )
            .unwrap()
            .then(() => {
              dispatch(getUserData());
              dispatch(resetTasksIds());
            });

          if (case_studies_id.length > 0)
            dispatch(add_case_studies(sortArray([...case_studies_id])))
              .unwrap()
              .then(() => {
                dispatch(resetCSIds());
              });
          if (system_wguides_id.length > 0)
            dispatch(add_wsystem_guides(sortArray([...system_wguides_id])));

          dispatch(
            add_guides_to_dashboard(
              sortArray([
                ...results.guides,
                ...parenting_guides_id,
                ...guides_for_all,
              ])
            )
          )
            .unwrap()
            .then(() => {
              dispatch(resetGuidesIds());
              dispatch(resetCSIds());
            });
        });

      handleNextStep();
    },
  });

  return (
    <form id="form-13" onSubmit={formik.handleSubmit} className={s.form}>
      <span className={s.stepMark}>{`${activeStep} `}</span>

      <>
        <Htag tag="h3" className="mb10">
          Do you and your ex have a pre-nuptial or post-nuptial agreement?
        </Htag>
        <div className={s.form_oneline}>
          <div className={s.formField}>
            <RadioGroup row name="nuptial_agreement" className={s.radioboxes}>
              <FormControlLabel
                value="yes"
                control={<Radio color="default" onChange={updateInterview} />}
                label="Yes"
                checked={formik.values.nuptial_agreement == "yes"}
              />
              <FormControlLabel
                value="no"
                control={<Radio color="default" onChange={updateInterview} />}
                label="No"
                checked={formik.values.nuptial_agreement == "no"}
              />
            </RadioGroup>
            {formik.touched.nuptial_agreement &&
              formik.errors.nuptial_agreement && (
                <ErrorField error={formik.errors.nuptial_agreement} />
              )}
          </div>
        </div>
      </>

      <Accordion className={s.desc} accordionData={answers} />

      <div className={s.groupButton}>
        <Button
          onClick={() => handleBack()}
          apearance="default"
          className={s.goBack}
          type="button"
        >
          Previous
        </Button>
        <Button
          type="submit"
          disabled={loading || loadingTasks || loadingGuides}
          apearance="secondary"
          className={cn({
            [s.disabled]: loading || loadingTasks || loadingGuides,
          })}
        >
          Submit
        </Button>
      </div>
    </form>
  );
};
