import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ALL_TASKS,
  DELETE_TASK,
  GET_DIVORCE_STEP,
  GET_TASK,
  SET_TASK_STATUS,
} from "../config";
import { IInitState } from "../root-interface";
import { ITask, ResponseTypeTasks } from "./tasks-interface";

import { API_TYPE, getRequestFabric } from "store/thunk-helper";

export const loadTasks = createAsyncThunk<
  ResponseTypeTasks[],
  number,
  { getState: IInitState; rejectValue: string }
>(
  "loadTasks",
  async function (step_id, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.GET,
      getState,
      ALL_TASKS(step_id),
      rejectWithValue,
      dispatch
    );
  }
);

export const loadSingleTask = createAsyncThunk<
  ITask,
  string,
  { getState: IInitState; rejectValue: string }
>(
  "@@tasks/loadTask",
  async function (task_id, { getState, rejectWithValue, dispatch }) {
    return (await getRequestFabric(
      API_TYPE.GET,
      getState,
      GET_TASK(task_id),
      rejectWithValue,
      dispatch
    )) as ITask;
  }
);

export const deleteTask = createAsyncThunk<
  { task_id: number },
  number | undefined,
  { getState: IInitState; rejectValue: string }
>(
  "@@tasks/delete",
  async function (task_id, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.DELETE,
      getState,
      DELETE_TASK(task_id),
      rejectWithValue,
      dispatch
    );
  }
);
