import { Avatar } from "@mui/material";

import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../utils/hooks";
import { resetToDefault } from "../../store/login/login-reducer";

import s from "./UserInfo.module.css";
import { useEffect, useState } from "react";
import { logoutAction } from "store/login/login-thunk";

export const UserInfo = (): JSX.Element => {
  const { name, email, role } = useAppSelector((state) => state.userState.user);

  const [status, setStatus] = useState<{
    status: string;
    expired_date: string;
  }>();

  const subscription = useAppSelector(
    (state) => state.accountState.subscription
  );

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const logout = (): void => {
    dispatch(logoutAction());
    localStorage.removeItem("userToken");
    dispatch(resetToDefault());

    navigate("/");
    if (window.Echo) {
      window.Echo.disconnect();
      window.Echo.leave("Subscribe");
    }
  };

  useEffect(() => {
    setStatus(subscription);
  }, [subscription]);

  return (
    <div className={s.dashboardUserData}>
      <Avatar className={s.avatar} sx={{ bgcolor: "#4EB072" }}>
        {name[0]}
      </Avatar>
      <p onClick={() => navigate("/user")} className={s.goToAccount}>
        <strong>{`${name}`}</strong>
        <br />
        {email}
      </p>

      {role == "user" && status && status.status !== "" ? (
        status.status !== undefined ? (
          <p className={s.accActive}>
            Your account is <span>{subscription.status}</span>
          </p>
        ) : (
          <p>
            You're using a trial account. For access to our content, please buy
            a subscription.
          </p>
        )
      ) : (
        ""
      )}
      <p className={s.logoutLink} onClick={logout}>
        <span>Log out</span>
      </p>
    </div>
  );
};
