import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "utils/hooks";

import s from "./Wellbeing.module.css";
import { Page404 } from "../../404/404.component";
import { ArticleContent } from "components";

import { loadSingleGuide } from "store/wellbeing/wellbeing-thunk";
import { IGuide } from "store/system_guides/system_guide.interface";

export const WellbeingArticleComponent = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const getId = (str: string | undefined) => {
    if (!str) return;
    const numsStr = str.replace(/[^0-9]/g, "");
    return parseInt(numsStr);
  };
  const item: IGuide = useAppSelector((state) => {
    return state.wellbeing.guides.filter(
      (item: IGuide) => item.id == getId(id)
    )[0];
  });

  useEffect(() => {
    if (!getId(id)) return;
    if (!item || !Object.prototype.hasOwnProperty.call(item, "content")) {
      dispatch(loadSingleGuide(Number(getId(id))));
    }
  }, []);

  return (
    <>
      {item ? (
        <div className={s.article}>
          <ArticleContent data={item} type="wguides" />
        </div>
      ) : (
        <Page404 className={s.componentNotFound} />
      )}
    </>
  );
};
