import { createAsyncThunk } from "@reduxjs/toolkit";
import { GET_ALL_CATEGORIES } from "store/config";
import { IInitState } from "store/root-interface";
import { API_TYPE, getRequestFabric } from "store/thunk-helper";
import { Category } from "./categories.interface";

export const getAllPCategories = createAsyncThunk<
  Category[],
  undefined,
  { getState: IInitState; rejectValue: string }
>(
  "@@parenting/allCategories",
  async function (_, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.GET,
      getState,
      GET_ALL_CATEGORIES(2),
      rejectWithValue,
      dispatch
    );
  }
);

export const getAllWCategories = createAsyncThunk<
  Category[],
  undefined,
  { getState: IInitState; rejectValue: string }
>(
  "@@wellbeing/allCategories",
  async function (_, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.GET,
      getState,
      GET_ALL_CATEGORIES(1),
      rejectWithValue,
      dispatch
    );
  }
);
