import s from "./GeneralOnboarding.module.css";

import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "utils/hooks";

import { Accordion, Button, ErrorField, Htag } from "components";
import * as yup from "yup";
import { useFormik } from "formik";
import { QuestionnaireProps } from './Questionnaire.props';
const validationForm12 = yup.object({
  considerable_wealth: yup
    .string()
    .nullable()
    .required("Please select an option."),
  inherited_wealth: yup
    .string()
    .nullable()
    .required("Please select an option."),
});

const desc = `
  <p>One of the factors that can be relevant when sorting out finances on a divorce is whether either person brought wealth into the relationship from what lawyers call ‘a non-matrimonial source’.</p>  

	<p>This basically means money which was earned before the relationship started (so, maybe you had already bought a property before you got together) or money which someone outside of the relationship earned (so perhaps inheritance your ex received after the death of their parent).</p>   

	<p>It’s not as simple as ignoring any money from a ‘non-matrimonial source’ and if these factors might be relevant to you, we’ll give you some resources to explain more.</p>`;
export const FormGB12 = ({
  handleBack,
  handleNextStep,
  setData,
}: QuestionnaireProps): JSX.Element => {
  const { generalOnboarding, activeStep } = useAppSelector(
    (state) => state.generalOnBoardingState
  );

  const dispatch = useAppDispatch();

  const updateInterview = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = ev.target;

      formik.handleChange(ev);
      setData(name, value);
    },
    [dispatch, generalOnboarding]
  );

  const formik = useFormik({
    initialValues: generalOnboarding,
    validationSchema: validationForm12,
    onSubmit: () => {
      handleNextStep();
    },
  });

  return (
    <form id="form-12" onSubmit={formik.handleSubmit} className={s.form}>
      <span className={s.stepMark}>{`${activeStep} `}</span>

      <>
        <Htag tag="h3" className='mb10'>
          Did you or your ex have considerable wealth (by this we mean over
          £100,000) before you got together?
        </Htag>
        <div className={s.form_oneline}>
          <div className={s.formField}>
            <RadioGroup row name="considerable_wealth" className={s.radioboxes}>
              <FormControlLabel
                value="yes"
                control={<Radio color="default" onChange={updateInterview} />}
                label="Yes"
                checked={formik.values.considerable_wealth == "yes"}
              />
              <FormControlLabel
                value="no"
                control={<Radio color="default" onChange={updateInterview} />}
                label="No"
                checked={formik.values.considerable_wealth == "no"}
              />
            </RadioGroup>
            {formik.touched.considerable_wealth &&
              formik.errors.considerable_wealth && (
                <ErrorField error={formik.errors.considerable_wealth} />
              )}
          </div>
        </div>
        <Htag tag="h3" style={{ marginTop: "20px" }}>
          Have you or your ex inherited any wealth?
        </Htag>
        <div className={s.form_oneline}>
          <div className={s.formField}>
            <RadioGroup row name="inherited_wealth" className={s.radioboxes}>
              <FormControlLabel
                value="yes"
                control={<Radio color="default" onChange={updateInterview} />}
                label="Yes"
                checked={formik.values.inherited_wealth == "yes"}
              />
              <FormControlLabel
                value="no"
                control={<Radio color="default" onChange={updateInterview} />}
                label="No"
                checked={formik.values.inherited_wealth == "no"}
              />
            </RadioGroup>
            {formik.touched.inherited_wealth &&
              formik.errors.inherited_wealth && (
                <ErrorField error={formik.errors.inherited_wealth} />
              )}
          </div>
        </div>
      </>

      <Accordion
        className={s.desc}
        accordionData={[
          {
            title: `Why are we asking these questions?`,
            content: desc,
          },
        ]}
      />

      <div className={s.groupButton}>
        <Button
          onClick={() => handleBack()}
          apearance="default"
          className={s.goBack}
          type="button"
        >
          Previous
        </Button>
        <Button type="submit" apearance="secondary">
          Next
        </Button>
      </div>
    </form>
  );
};
