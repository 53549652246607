import s from "./GeneralOnboarding.module.css";

import { Accordion, Button, Htag } from "components";
import {
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
} from "@mui/material";

import { useState } from "react";
import { addId, removeId } from "store/system_tasks/system_tasks-reducer";
import { useAppDispatch, useAppSelector } from "utils/hooks";

import {
  addGuideId,
  removeGuideId,
} from "store/system_guides/system_guide-reducer";
import { QuestionnaireProps } from './Questionnaire.props';

const desc = `<p>A parenting plan is a document that describes an agreement between you and your ex about how you are going to share the care of your children.  It might be very detailed or more like a list of guidelines.</p>
  <p>The idea is that a written plan you’ve designed together and each signed will provide you with a clear framework for working together now and in the future.  It will ensure that you’re embarking on your co-parenting with a set of agreed principles in mind.  It will also give you something to refer back to if you disagree about an issue later on.</p>`;

export const FormCB4 = ({
  handleBack,
  setData,
  handleNextStep,
}: QuestionnaireProps): JSX.Element => {
  const { activeStep, generalOnboarding } = useAppSelector(
    (state) => state.generalOnBoardingState
  );

  const [value, setValue] = useState(generalOnboarding.parenting_plan);
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState("");

  const dispatch = useAppDispatch();

  const updateInterview = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = ev.target;

    setHelperText("");
    setError(false);

    if (value === "yes") {
      setValue(value);
      dispatch(removeId("C21"));
      dispatch(removeGuideId(["CR7A"]));
    } else if (value == "no") {
      setValue(value);
      dispatch(addId("C21"));
      dispatch(addGuideId("CR7A"));
    }
    setData?.(name, value);
  };

  const submitHandle = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();

    if (value === "yes" || value === "no") {
      setHelperText("");
      handleNextStep?.();
      setError(false);
    } else {
      setHelperText("Please select an option.");
      setError(true);
    }
  };

  return (
    <form id="form-4" onSubmit={submitHandle} className={s.form}>
      <span className={s.stepMark}>{`${activeStep} `}</span>

      <>
        <Htag tag="h3" className='mb10'>
          Do you and your ex have a parenting plan for your children?
        </Htag>
        <div className={s.form_oneline}>
          <div className={s.formField}>
            <RadioGroup row name="parenting_plan" className={s.radioboxes}>
              <FormControlLabel
                value="yes"
                control={
                  <Radio
                    color="default"
                    checked={generalOnboarding.parenting_plan === "yes"}
                    onChange={updateInterview}
                  />
                }
                label="Yes."
              />
              <FormControlLabel
                value="no"
                control={
                  <Radio
                    color="default"
                    checked={generalOnboarding.parenting_plan === "no"}
                    onChange={updateInterview}
                  />
                }
                label="No."
              />
            </RadioGroup>
            {error && (
              <FormHelperText className={s.error}>{helperText}</FormHelperText>
            )}
          </div>
        </div>
      </>

      <Accordion
        className={s.desc}
        accordionData={[
          {
            title: `What’s a parenting plan?`,
            content: desc,
          },
        ]}
      />

      <div className={s.groupButton}>
        <Button
          onClick={() => handleBack()}
          apearance="default"
          type="button"
          className={s.goBack}
        >
          Previous
        </Button>
        <Button type="submit" apearance="secondary">
          Next
        </Button>
      </div>
    </form>
  );
};
