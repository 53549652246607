import s from "./Task.module.css";
import cn from "classnames";
import { deleteTask } from "../../../store/tasks/tasks-thunk";
import { useAppDispatch, useAppSelector } from "../../../utils/hooks";
import { Draggable } from "react-beautiful-dnd";
import { useState } from "react";
import { Checkbox } from "@mui/material";

import { Card, Htag } from "../..";

import { getDots, getFirstSentence } from "../../../utils/utils";
import ReactHtmlParser from "html-react-parser";
import { setNewTaskStatus } from "store/divorceSteps/divorceSteps.thunk";
import { TaskItemProps } from "./Task.props";
import { TaskModalView } from "components/TaskModalView/TaskModalView.component";
import { TaskItemView } from "./TaskItemView.component";

const TaskItem = ({ task, ...props }: TaskItemProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const boards = useAppSelector((state) => state.tasks.boards);

  const [openTask, setOpenTask] = useState<boolean>(false);
  const handleOpenTask = (e: any) => {
    setOpenTask(true);
  };
  const handleCloseTask = (): void => {
    setOpenTask(false);
  };

  const handleDeleteTask = () => {
    dispatch(deleteTask(task.id));
    handleCloseTask();
  };

  const handleSetStatus = (id: number): void => {

    const el = {
      task_id: task.id,
      status: id,
      sort: boards[id].tasks.length,
    };

    dispatch(setNewTaskStatus(el));
  };

  return (
    <>
      <Draggable
        draggableId={task.id ? task.id.toString() : ""}
        index={props.index}
      >
        {(provided) => (
          <div
            className={s.taskCardContainer}
            {...provided.dragHandleProps}
            {...provided.draggableProps}
            ref={provided.innerRef}
            {...props}
          >
            <Card className={cn(s.taskCard)}>
              <div className={s.activeTitle}>
                <div className={s.checkbox}>
                  <Checkbox
                    color="default"
                    name="terms"
                    onChange={(e) => {
                      handleSetStatus(Number(!task.status));
                    }}
                    checked={!!task.status}
                    className={task.status ? s.checkboxChecked : s.unchecked}
                  />
                </div>
              
                <Htag tag="h3" onClick={handleOpenTask}>
                  {task.title}
                </Htag>
              </div>
              {task.description && (
                <div className={s.shortDesc} onClick={handleOpenTask}>
                  {ReactHtmlParser(getFirstSentence(task.description))}
                </div>
              )}{" "}
            </Card>
          </div>
        )}
      </Draggable>

      <TaskModalView open={openTask} close={handleCloseTask}>
        <TaskItemView
          complete={(value: number) => handleSetStatus(value)}
          task={task}
          deleteItem={handleDeleteTask}
        />
      </TaskModalView>
    </>
  );
};

export default TaskItem;
