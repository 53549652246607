import { createAsyncThunk } from "@reduxjs/toolkit";
import { DELETE_ATTACH, DELETE_TASK_ATTACH } from "../config";
import { IInitState } from "../root-interface";
import { getRequestFabric, API_TYPE } from "../thunk-helper";

export enum ATTACH_TYPE {
  AGENDA,
  LEGAL,
  LETTER,
  MAIL,
  SCRIPT,
  SPREADSHEET,
}

export const attachType: readonly string[] = [
  "Agenda",
  "Legal",
  "Letter",
  "Mail",
  "Script",
  "Spreadsheet",
];

export const deleteAttach = createAsyncThunk<
  number,
  number,
  { getState: IInitState; rejectValue: string }
>(
  "@@attachments/deleteAtach",
  async function (id, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.DELETE,
      getState,
      DELETE_ATTACH(id),
      rejectWithValue,
      dispatch
    );
  }
);

export const deleteTaskAttach = createAsyncThunk<
  number,
  number,
  { getState: IInitState; rejectValue: string }
>(
  "@@attachments/deleteTaskAtach",
  async function (id, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.DELETE,
      getState,
      DELETE_TASK_ATTACH(id),
      rejectWithValue,
      dispatch
    );
  }
);
