import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ADD_EXTRA_RESOURCES,
  DELETE_EXTRA_RESOURCES,
  EDIT_EXTRA_RESOURCES,
  GET_ALL_EXTRA_GUIDES,
  GET_EXTRA_RESOURCE,
} from "../config";
import { IInitState } from "../root-interface";
import { IExtraGuide } from "./extra_guide.interface";
import { API_TYPE, getRequestFabric } from "../thunk-helper";

export const get_all_extra_guides = createAsyncThunk<
  IExtraGuide[],
  undefined,
  { getState: IInitState; rejectValue: string }
>(
  "@@extraguides/getting",
  async function (_, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.GET,
      getState,
      GET_ALL_EXTRA_GUIDES(),
      rejectWithValue,
      dispatch
    );
  }
);

export const get_single_eguide = createAsyncThunk<
  IExtraGuide,
  string,
  { getState: IInitState; rejectValue: string }
>(
  "@@extraguides/gettingSingleGuide",
  async function (id, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.GET,
      getState,
      GET_EXTRA_RESOURCE(Number(id)),
      rejectWithValue,
      dispatch
    );
  }
);

export const updateExtraGuide = createAsyncThunk<
  { success: string; guide: IExtraGuide },
  {
    er_id: string;
    er_data: FormData;
  },
  { getState: IInitState; rejectValue: string }
>(
  "@@extraguides/updateGuide",
  async function ({ er_id, er_data }, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.POST_DATA,
      getState,
      EDIT_EXTRA_RESOURCES(er_id),
      rejectWithValue,
      dispatch,
      er_data
    );
  }
);

export const deleteEGuide = createAsyncThunk<
  { success: string; id: number },
  number,
  { getState: IInitState; rejectValue: string }
>(
  "@@extraguides/deleteGuide",
  async function (guide_id, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.DELETE,
      getState,
      DELETE_EXTRA_RESOURCES(guide_id),
      rejectWithValue,
      dispatch
    );
  }
);

export const addExtraGuide = createAsyncThunk<
  { success: string; guide: IExtraGuide },
  FormData,
  { getState: IInitState; rejectValue: string }
>(
  "@@extraguides/addGuide",
  async function (data, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.POST_DATA,
      getState,
      ADD_EXTRA_RESOURCES(),
      rejectWithValue,
      dispatch,
      data
    );
  }
);
