import {
  Alert,
  FormControl,
  MenuItem,
  Snackbar,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  Button,
  EditorComponent,
  ErrorField,
  Htag,
  InputFileComponent,
  InputImageComponent,
  TextArea,
} from "components";
import s from "../SpecialSources.module.css";
import { NavLink, useParams } from "react-router-dom";

import {
  get_single_sguide,
  updateSystemGuide,
} from "store/system_guides/system_guide-thunk";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { useFormik } from "formik";
import { validationCreateSchema } from "../validation";
import { FileProps, IAttach } from "store/system_guides/system_guide.interface";
import { divorceSteps } from "store/divorceSteps/divorceSteps.interface";
import { Guide } from "models/Guide.model";

import cn from "classnames";
import InputTags from "components/Tags/Tags.component";
import { commonSelectProps } from "../WellbeingAdmin";
import { getAllPCategories } from "store/categories/categories.thunk";

export const EditSRPage = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const system_guide = useAppSelector((state) =>
    state.specialGuidesState.guides_db.filter(
      (guide) => guide.id == Number(id)
    )
  )[0];

  const categories = useAppSelector((state) => state.parenting.categories);

  const [currentAttachments, setAttachments] = useState<
    Partial<FileProps | IAttach>[]
  >([]);
  const [attachmentsOld, setOldAttachments] = useState<
    Partial<FileProps | IAttach>[]
  >([]);

  const { error } = useAppSelector((state) => state.specialGuidesState);

  const formik = useFormik({
    initialValues: new Guide(),
    validationSchema: validationCreateSchema,
    onSubmit: (values) => {
      if (!id) return;

      const updatedValue = formik.initialValues.formData(values);

      try {
        dispatch(
          updateSystemGuide({
            sg_id: id,
            sg_data: updatedValue,
          })
        )
          .unwrap()
          .then(() => {
            setOpenSnackbar(true);
          });
      } catch (error) {
        console.log(error);
      }
    },
  });

  const handleAtachments = (attachments: FileProps | IAttach) => {
    setAttachments((old) => [...old, attachments]);

    formik.setFieldValue("attachments", [...currentAttachments, attachments]);
  };

  useEffect(() => {
    if (!id) return;
    if (!system_guide || !system_guide.content) {
      dispatch(get_single_sguide(id));
    }
  }, []);

  useEffect(() => {
    if (!system_guide) return;
    try {
      const fields = {
        content: system_guide.content || "",
        step_id: system_guide.step_id || null,
        title: system_guide.title || "",
        unique_number: system_guide.unique_number,
        reading_time: system_guide.reading_time || "",
        category_id: system_guide.category_id || null,
        image: system_guide.image,
        tags: system_guide.tags,
        domestic_abuse: system_guide.domestic_abuse,
		type: system_guide.type
      };

      Object.entries(fields).forEach(([key, value]) => {
        formik.setFieldValue(key, value);
      });
      setOldAttachments(system_guide.attachments);
    } catch (error) {
      console.error("Error fetching guide:", error);
    }
  }, [system_guide]);

  useEffect(() => {
    dispatch(getAllPCategories());
  }, []);

  return (
    <div className={s.formsWrap}>
      <div className={s.post_header}>
        <Htag tag="h3">Edit guide: "{formik.values.title}" </Htag>
        <Htag tag="h4">Unique number: {formik.values.unique_number} </Htag>
      </div>

      <form onSubmit={formik.handleSubmit}>
        <div className={s.formField}>
          <Htag tag="h4">Title</Htag>
          <FormControl fullWidth className={s.title}>
            <input
              id="title"
              name="title"
              type="text"
              onBlur={formik.handleBlur}
              value={formik.values.title}
              onChange={formik.handleChange}
              placeholder="Title"
            />
            {formik.touched.title && formik.errors.title && (
              <ErrorField error={formik.errors.title} />
            )}
          </FormControl>
        </div>
        <div className={cn(s.formField, s.reading_timeField)}>
          <Htag tag="h4">Reading time: </Htag>
          <FormControl className={s.reading_time}>
            <input
              id="reading_time"
              name="reading_time"
              type="text"
              onBlur={formik.handleBlur}
              value={formik.values.reading_time}
              onChange={formik.handleChange}
              placeholder="Reading time"
            />
            {formik.touched.reading_time && formik.errors.reading_time && (
              <ErrorField error={formik.errors.reading_time} />
            )}
          </FormControl>
        </div>
        <div className={s.formField}>
          <Htag tag="h4" className={s.headline}>
            Upload preview image
          </Htag>
          <InputImageComponent
            handleFiles={formik.setFieldValue}
            handleOnBlur={formik.setFieldValue}
            file={formik.values.image}
            id="image"
            name="image"
          />
        </div>
        <div className={s.formField}>
          <Htag tag="h4" className={s.headline}>
            Upload attachments
          </Htag>
          <InputFileComponent
            files={attachmentsOld}
            handleFiles={handleAtachments}
            id="EditSR"
          />
        </div>

        <div className={s.wyswyg_editorWrap}>
          <EditorComponent
            value={formik.values.content}
            name="content"
            handleChange={(e) => formik.setFieldValue("content", e)}
          />

          {formik.touched.content && formik.errors.content && (
            <ErrorField error={formik.errors.content} />
          )}
        </div>
        <div>
          <Htag tag="h3">Add tags:</Htag>

          <InputTags
            hashtag={formik.values.tags ? formik.values.tags : []}
            handleTags={(data) => formik.setFieldValue("tags", data)}
          />
        </div>
        <div className={s.formField}>
          <Htag tag="h4">Domestic Abuse:</Htag>

          {/*   <TextArea
            error={
              formik.touched.domestic_abuse && formik.errors.domestic_abuse
                ? formik.errors.domestic_abuse
                : ""
            }
            name="domestic_abuse"
            id="domestic_abuse"
            value={formik.values.domestic_abuse || ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="Type your text here, max 500 characters"
          /> */}
          <EditorComponent
            value={formik.values.domestic_abuse}
            handleChange={(e) => formik.setFieldValue("domestic_abuse", e)}
            name="domestic_abuse"
          />
        </div>
        <div className={cn(s.selectsWrap, "mb32")}>
          {formik.values.type == 1 && (
            <div className={s.select}>
              <Htag tag="h4" className="mb10">
                Connect to divorce step:
              </Htag>
              {
                <TextField
                  {...commonSelectProps}
                  name="step_id"
                  label={formik.values.step_id === null ? "Task is…" : ""}
                  defaultValue={
                    formik.values.step_id === null ? "" : formik.values.step_id
                  }
                  value={
                    formik.values.step_id === null ? "" : formik.values.step_id
                  }
                  onChange={formik.handleChange}
                >
                  {" "}
                  {divorceSteps.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              }
              {error &&
                Array.isArray(error) &&
                error.map((item, index) => (
                  <ErrorField
                    key={index}
                    error={`Server error: ${Object.values(item)[0]}`}
                  />
                ))}
            </div>
          )}

          {formik.values.type == 2 && (
            <div className={s.select}>
              <Htag tag="h4" className="mb10">
                Choose category:
              </Htag>
              {
                <TextField
                  {...commonSelectProps}
                  name="category_id"
                  label={
                    formik.values.category_id === null ? "Category is…" : ""
                  }
                  defaultValue={
                    !formik.values.category_id ? "" : formik.values.category_id
                  }
                  value={
                    formik.values.category_id === null
                      ? ""
                      : formik.values.category_id
                  }
                  onChange={formik.handleChange}
                >
                  <MenuItem value={JSON.stringify(null)}>
                    <em>None</em>
                  </MenuItem>
                  {categories.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.title}
                    </MenuItem>
                  ))}
                </TextField>
              }
            </div>
          )}
        </div>

        {error && typeof error == "string" && <ErrorField error={error} />}
        <p className={s.previewLink}>
          <NavLink
            target="_blank"
            to={`/admin-dashboard/guides/edit-guide/${id}/preview`}
          >
            Preview
          </NavLink>
        </p>

        <Button apearance="secondary" type="submit">
          Save
        </Button>
      </form>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          System guide "{system_guide?.title}" is updated!
        </Alert>
      </Snackbar>
    </div>
  );
};
