import { Category } from "store/categories/categories.interface";
import { Article } from "./Article.model";
import { IGuide } from "store/system_guides/system_guide.interface";

export class Guide extends Article {
  private _tags: string[];
  image: Blob | null | string;
  unique_number: string;
  attachments: any[];
  category?: Category;
  step_id?: number | null;
  category_id?: number | null;
  domestic_abuse?: string | null;
  type: number;

  constructor(
    title = "",
    content = "",
    reading_time = "",
    tags = [],
    image = "",
    unique_number = "",
    attachments = [],
    step_id = null,
    category_id = null,
    domestic_abuse = null,
	type = 1
  ) {
    super(title, content, reading_time);
    this.image = image;
    this._tags = tags;
    this.unique_number = unique_number;
    this.attachments = attachments;
    this.step_id = step_id;
    this.category_id = category_id;
    this.domestic_abuse = domestic_abuse;
	this.type = type;
  }

  get tags() {
    return this._tags;
  }

  set tags(tags) {
    this._tags = tags;
  }

  formData(values: Guide) {
    const formData = new FormData();

    formData.append("title", values.title);
    formData.append("content", values.content);
    formData.append("reading_time", values.reading_time);
    if (values.unique_number)
      formData.append("unique_number", values.unique_number);

    if (values.domestic_abuse) {
      formData.append("domestic_abuse", values.domestic_abuse);
    } else {
      formData.append("domestic_abuse", "");
    }

    // formData.append("type", "guide");
    if (values.image) {
      formData.append("image", values.image);
    } else {
      formData.append("image", "");
    }

    if (values.category_id) {
      formData.append("category_id", values.category_id.toString());
    } else {
      formData.append("category_id", JSON.stringify(null));
    }

    if (!values.tags?.length) formData.append("tags", "");
    values.tags?.map((tag: string) => {
      formData.append("tags[]", tag);
    });

    if (values.step_id) {
      formData.append("step_id", values.step_id.toString());
    } else if (Number.isNaN(values.step_id)) {
      formData.append("step_id", JSON.stringify(null));
    }
    values.attachments.map((file: any) => {
      formData.append("attachments[]", file);
    });
    return formData;
  }
}
