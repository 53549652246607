import { Htag, Notification } from "components";

import { useAppDispatch, useAppSelector } from "utils/hooks";
import s from "./Parenting.module.css";

import { loadParenting } from "store/parenting/parenting-thunk";
import { useEffect, useState } from "react";
import { BoardsComponent } from "../../../components/Boards/Boards.component";


import { IMemory } from "store/memory/memory-thunk";
import { getVariableForWidth } from "utils/utils";

import { setChildrenGuides } from "store/parenting/parenting-reducer";
import { getAllPCategories } from "store/categories/categories.thunk";
import cn from "classnames";
import useGroupBy from "utils/groupBy.hook";
import { Category } from "store/categories/categories.interface";
import { setBoards, setTemplates } from "store/tasks/tasks-reducer";
import ScrollGallery from "components/ScrollGallery/ScrollGallery.component";

export const ParentingPage = () => {
  const dispatch = useAppDispatch();

  const categories = useAppSelector((state) => state.parenting.categories);

  const [index, setIndex] = useState(4);
  const templates = useAppSelector((state) => state.tasks.templates);
  const { boards } = useAppSelector((state) => state.tasks);

  const parentingMemory = useAppSelector((state) =>
    state.userState.user.memory.filter(
      (item: IMemory) => item.name == `Notification_Children_2`
    )
  );

  const parenting = useAppSelector((state) => state.parenting);

  const [mergedGuides, setMergeGuides] = useState<Category[]>([]);

  const groupedGuides = useGroupBy(parenting.spacial_guides, "category_id");

  useEffect(() => {
    if (!categories.length) return;
    const arr: Category[] = Object.keys(groupedGuides)
      .filter((item) => item !== "null" && item !== null)
      .map((item) => {
        const ctData = categories.filter((ct) => ct.id == Number(item))[0];

        return {
          id: Number(item),
          title: ctData.title,
          guides: groupedGuides[item],
          type: ctData.type,
        };
      });
    if (arr && arr.length) setMergeGuides(arr);
  }, [parenting, categories]);

  useEffect(() => {
    dispatch(loadParenting())
      .unwrap()
      .then((resp: any) => {
        setChildrenGuides(resp);
        dispatch(setBoards(resp.user_tasks));
        dispatch(setTemplates(resp.user_tasks));
      });
    dispatch(getAllPCategories());
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const w = getVariableForWidth(window.innerWidth);

      if (w == "small") {
        setIndex(parenting.spacial_guides.length);
      } else {
        setIndex(4);
      }
    };

    window.addEventListener("resize", handleResize);
    if (!parenting.spacial_guides.length) {
      window.addEventListener("resize", handleResize);
    } else {
      handleResize();
    }
    return () => window.removeEventListener("resize", handleResize);
  }, [parenting]);

  return (
    <>
      <Htag tag="h1">Focus on parenting</Htag>
      {parentingMemory[0]?.value !== "true" && (
        <Notification name={`Children_2`}>
          This is your space for supporting your children, sorting out your
          childcare and parenting arrangements. All the actions and guides have
          been designed by our experts to minimise the impact of divorce on your
          family. We have experts in family law, finances and child psychology.
        </Notification>
      )}
      <BoardsComponent boards={boards} />

      {mergedGuides.length > 0 &&
        mergedGuides.map((ct, index) => (
          <div key={index} className={cn(s.guidesWrap, "mb32")}>
            {ct.guides && ct.guides.length > 0 && (
              <>
                <Htag tag="h2">{ct.title}</Htag>{" "}
              
                <ScrollGallery
                  data={ct.guides}
                  showTgas={true}
                  type="default"
                />
              </>
            )}
          </div>
        ))}

      {templates.length > 0 && (
        <section className={s.guidesWrap}>
          <header className={s.guides_header}>
            <Htag tag="h2">Templates</Htag>
          </header>

          <ScrollGallery data={templates} showTgas={true} type="template" />
        </section>
      )}
    </>
  );
};
