import React, { useState, useEffect, useMemo } from "react";
import { ScrollGalleryProps } from "./ScrollGalleryProps";
import { Chip, List, ListItem } from "@mui/material";
import s from "./ScrollGallery.module.css";
import { Card, ClockIcon, NoImgIcon } from "components";
import { useNavigate } from "react-router-dom";
import cn from "classnames";
import { downloadAttach } from "controlls/tasks-controll";
import { templatePreview } from "./Tepmlates.images";
import { Agenda, Legal, Letter, Mail, Script, Spreadsheet } from "components";

const templateImage = [
  <Agenda />,
  <Legal />,
  <Letter />,
  <Mail />,
  <Script />,
  <Spreadsheet />,
];

const ScrollGallery = ({ data, showTgas, type }: ScrollGalleryProps) => {
  const [showAll, setShowAll] = useState(false);
  const [activeTag, setActiveTag] = useState("");
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 770);
  const navigate = useNavigate();

  // Debounce window resize event
  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 770);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    setShowAll(false);
  }, [data]);

  const tags = useMemo(() => {
    if (!showTgas) return [];
    return [...new Set(data.flatMap((item: any) => item.tags || []))];
  }, [data, showTgas]);

  const filteredData = useMemo(() => {
    if (!showTgas || activeTag === "") return data;
    return data.filter((item: any) =>
      item.tags?.some((tag: string) => tag === activeTag)
    );
  }, [data, activeTag, showTgas]);

  const displayedData = useMemo(() => {
    return isDesktop && !showAll ? filteredData.slice(0, 4) : filteredData;
  }, [filteredData, isDesktop, showAll, data]);

  const handleTagFilter = (tag: string) => {
    setActiveTag(tag);
    setShowAll(true);
  };

  const getRightId = (item: any, type?: string) => {
    const id = item.resource_id || item.id;
    return type === "wguide" || item.is_wellbeing_resource
      ? `/dashboard/my-wellbeing/guide/${id}`
      : `/dashboard/guides/${id}`;
  };

  const handleCardAction = (item: any) => {
    if (type === "default") {
      navigate(getRightId(item, item.type));
    } else if (type === "template" && item.task_id && item.name) {
      downloadAttach(item.id, +item.task_id, item.name);
    }
  };

  return (
    <div className="scroll-gallery">
      
      {((isDesktop && data.length > 4) || tags.length > 0) && (
        <div className={s.filterWrap}>
          <div className={cn(s.tagsList, "mb15", s.mobile_scroll_container)}>
            {showTgas && tags.length > 0 && (
              <Chip
                className={s.tag}
                label="All"
                variant="outlined"
                onClick={() => handleTagFilter("")}
              />
            )}
            {showTgas &&
              tags.map((tag) => (
                <Chip
                  key={tag}
                  className={cn(s.tag, { [s.activeTag]: tag === activeTag })}
                  label={tag}
                  variant="outlined"
                  onClick={() => handleTagFilter(tag)}
                />
              ))}
          </div>
          {isDesktop && data.length > 4 && (
            <span
              className={cn(
                { [s.showBtn]: showAll, [s.hideBtn]: !showAll },
                "hover-underline-animation_back"
              )}
              onClick={() => setShowAll((prev) => !prev)}
            >
              {showAll
                ? `Hide ${type === "default" ? "guides" : "templates"}`
                : `See all ${type === "default" ? "guides" : "templates"}`}
            </span>
          )}
        </div>
      )}
      <div className={s.listWrap}>
        <List className={s.guideList}>
          {displayedData.map((item) => (
            <ListItem key={item.id} className={s.listItem}>
              <Card
                className={s.guide_card}
                onClick={() => handleCardAction(item)}
              >
                <div
                  className={cn(
                    type === "default" ? s.guide_preview : s.template_preview,
                    {
                      [s[item.type]]:
                        type === "template" && item.type !== undefined,
                    }
                  )}
                >
                  {type === "template" && item.type !== undefined ? (
                    templateImage[+item.type]
                  ) : type === "default" && item.image ? (
                    <img src={item.image} alt="" />
                  ) : type === "default" ? (
                    <NoImgIcon />
                  ) : (
                    <img src={templatePreview} alt="" />
                  )}
                </div>
                <div className={s.guide_meta}>
                  <p className="b2">{item.title || item.name}</p>
                  <div className={s.guide_meta_extra}>
                    <Chip
                      label={type === "template" ? "Template" : "Guide"}
                      variant="outlined"
                      size="medium"
                      className={s.chip}
                    />
                    {item.reading_time && (
                      <span className={s.guide_meta_readingTime}>
                        <ClockIcon /> {item.reading_time}
                      </span>
                    )}
                  </div>
                </div>
              </Card>
            </ListItem>
          ))}
        </List>
      </div>
    </div>
  );
};

export default ScrollGallery;
