import s from "./GeneralOnboarding.module.css";
import { Accordion, Button, ErrorField, Htag } from "components";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Radio,
  RadioGroup,
} from "@mui/material";

import { useEffect, useRef, useState } from "react";
import { addId, removeId } from "store/system_tasks/system_tasks-reducer";

import { useAppDispatch, useAppSelector } from "utils/hooks";
import {
  addGuideId,
  removeGuideId,
} from "store/system_guides/system_guide-reducer";

import cn from "classnames";
import { setOnboarding } from "store/generalOnboarding/generalOnboarding-reducer";
import { QuestionnaireProps } from "./Questionnaire.props";

export const FormCB2 = ({
  handleBack,
  setData,
  handleNextStep,
}: QuestionnaireProps): JSX.Element => {
  const { activeStep, generalOnboarding } = useAppSelector(
    (state) => state.generalOnBoardingState
  );

  const [value, setValue] = useState<"yes" | "no" | null>(
    generalOnboarding.children_know
  );
  const [error, setError] = useState(false);

  const [checkboxError, setCheckboxError] = useState("");

  const [helperText, setHelperText] = useState("");

  const living_property = useAppSelector(
    (state) => state.generalOnBoardingState.generalOnboarding.living_property
  );
  const firstGroup = useRef<HTMLInputElement>(null);
  const secondGroup = useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();

  const updateAdultGroup = (ev: React.ChangeEvent<HTMLInputElement>) => {
 
    dispatch(
      setOnboarding({
        ...generalOnboarding,
        adultGroup: {
          ...generalOnboarding.adultGroup,
          [`${ev.target.name}`]: ev.target.checked,
        },
      })
    );
  };

  useEffect(() => {
    setCheckboxError("");
  }, [generalOnboarding.adultGroup]);

  const updateInterview = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = ev.target;

    setHelperText("");

    setError(false);

    if (value === "no") {
      setValue(value);
      if (generalOnboarding.agesGroup.babies) {
        dispatch(addId("C8"));
        dispatch(addGuideId("NG25"));
      }
      if (generalOnboarding.agesGroup.toddlers) {
        dispatch(addId("C9"));
        dispatch(addGuideId("NG26"));
      }
      if (generalOnboarding.agesGroup.teenagers) {
        dispatch(addId("C10"));
        dispatch(addGuideId("NG27"));
      }
      if (onlyAdult()) {
        dispatch(addId(["C11"]));
        dispatch(addGuideId("NG24"));
      }
     
    } else if (value === "yes") {
      setValue(value);
      dispatch(removeId(["C10", "C9", "C8", "C11"]));
      dispatch(removeGuideId(["NG24", "NG27", "NG26", "NG25"]));
   
    }
    setData?.(name, value);
  };

  const onlyAdult = (): boolean => {
    if (
      !generalOnboarding.agesGroup.teenagers &&
      !generalOnboarding.agesGroup.toddlers &&
      !generalOnboarding.agesGroup.babies &&
      generalOnboarding.agesGroup.adults
    ) {
      return true;
    } else return false;
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (generalOnboarding.agesGroup.adults) {
      if (
        !generalOnboarding.adultGroup.firstGroup &&
        !generalOnboarding.adultGroup.secondGroup
      ) {
        setCheckboxError("Please select an option.");
      } else {
        setCheckboxError("");
      }
    }

    if (value !== "yes" && value !== "no") {
      setHelperText("Please select an option.");
      setError(true);
    } else {
      setHelperText("");
      setError(false);
    }

    if (value && !error && !checkboxError) {
      handleNextStep?.();
    }
  };

  return (
    <form id="form-2" onSubmit={handleSubmit} className={s.form}>
      <span className={s.stepMark}>{`${activeStep} `}</span>

      <>
        <Htag tag="h3" className="mb15">
          We know that separation and divorce can be challenging for children of
          all ages. We’ll give you guides, scripts and templates to help you
          support your children and know you’re on the right track. To make
          these resources as helpful as possible for you, we’ve got a few more
          questions.
        </Htag>

        {generalOnboarding.agesGroup.adults && (
          <>
            <Htag tag="h3" className="mb10">
              Which age category do your adult children fall into? Please tick
              all that apply.
            </Htag>
            <div className={cn(s.form_oneline, "mb15")}>
              <div className={s.formField}>
                <FormGroup>
                  <FormControlLabel
                    checked={generalOnboarding.adultGroup.firstGroup}
                    name="firstGroup"
                    control={
                      <Checkbox
                        color="default"
                        onChange={(e) => updateAdultGroup(e)}
                        inputRef={firstGroup}
                      />
                    }
                    label="18-25"
                  />
                  <FormControlLabel
                    checked={generalOnboarding.adultGroup.secondGroup}
                    name="secondGroup"
                    control={
                      <Checkbox
                        color="default"
                        onChange={updateAdultGroup}
                        inputRef={secondGroup}
                      />
                    }
                    label="26+"
                  />
                </FormGroup>
                {checkboxError && <ErrorField error={checkboxError} />}
              </div>
            </div>
          </>
        )}

        {living_property == "different" && (
          <div className={s.box2}>
            <Htag tag="h3" className="mb10">
              Given you and your ex aren’t living together anymore do your
              children already know about your separation?
            </Htag>
            <div className={s.form_oneline}>
              <div className={s.formField}>
                <RadioGroup row name="children_know" className={s.radioboxes}>
                  <FormControlLabel
                    value="yes"
                    control={
                      <Radio
                        color="default"
                        checked={generalOnboarding.children_know == "yes"}
                        onChange={updateInterview}
                      />
                    }
                    label="Yes."
                  />
                  <FormControlLabel
                    value="no"
                    control={
                      <Radio
                        color="default"
                        checked={generalOnboarding.children_know == "no"}
                        onChange={updateInterview}
                      />
                    }
                    label="No."
                  />
                </RadioGroup>
                {error && <ErrorField error={helperText} />}
              </div>
            </div>
          </div>
        )}
        {living_property == "same" && (
          <>
            <Htag tag="h3">
              You’ve told us you and your ex are still living together. Do your
              children know about your separation?
            </Htag>
            <div className={s.form_oneline}>
              <div className={s.formField}>
                <RadioGroup row name="children_know" className={s.radioboxes}>
                  <FormControlLabel
                    value="yes"
                    control={
                      <Radio
                        color="default"
                        checked={generalOnboarding.children_know === "yes"}
                        onChange={updateInterview}
                      />
                    }
                    label="Yes."
                  />

                  <FormControlLabel
                    value="no"
                    control={
                      <Radio
                        color="default"
                        checked={generalOnboarding.children_know === "no"}
                        onChange={updateInterview}
                      />
                    }
                    label="No."
                  />
                </RadioGroup>
                {error && (
                  <FormHelperText className={s.error}>
                    {helperText}
                  </FormHelperText>
                )}
              </div>
            </div>
          </>
        )}
      </>

      <Accordion
        className={s.desc}
        accordionData={[
          {
            title: `Why are we asking this?`,
            content: `<p>If you need some support to plan how you are going to tell your children about what is happening in an age appropriate way, we can help.</p>`,
          },
        ]}
      />

      <div className={s.groupButton}>
        <Button
          onClick={() => handleBack()}
          apearance="default"
          className={s.goBack}
          type="button"
        >
          Previous
        </Button>
        <Button type="submit" apearance="secondary">
          Next
        </Button>
      </div>
    </form>
  );
};
