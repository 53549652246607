import { FormControlLabel, Radio, RadioGroup } from "@mui/material";

import { Accordion, Button, ErrorField, Htag } from "components";
import s from "./GeneralOnboarding.module.css";

import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { AccordionItem } from "components/Accordion/Accordion.props";
import { useFormik } from "formik";

import * as yup from "yup";
import { QuestionnaireProps } from "./Questionnaire.props";

const accordionData: AccordionItem[] = [
  {
    title: `Do I include adult children?`,
    content: `<p>Yes please. We'd like to know if you have any grown up children because we have some resources to help you understand how they might respond to your divorce.</p><p>We’re about to ask you about your children's ages so that we can give you the right resources for your family's circumstances.
</p>`,
  },
  {
    title: `What about step-children?`,
    content: `<p>Yes, please include any children who one or both of you have responsibilities for.</p>`,
  },
];

const validationForm3 = yup.object({
  has_children: yup.string().required("Please select an option."),
});

export const FormGB3 = ({
  handleBack,
  setData,
  handleNextStep,
}: QuestionnaireProps): JSX.Element => {
  const { generalOnboarding, activeStep } = useAppSelector(
    (state) => state.generalOnBoardingState
  );

  const dispatch = useAppDispatch();

  const updateInterview = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = ev.target;

      setData(name, value);
      formik.handleChange(ev);
    },
    [dispatch, generalOnboarding]
  );

  const formik = useFormik({
    initialValues: generalOnboarding,
    validationSchema: validationForm3,
    onSubmit: () => {
      handleNextStep();
    },
  });

  return (
    <form id="form-3" onSubmit={formik.handleSubmit} className={s.form}>
      <span className={s.stepMark}>{`${activeStep} `}</span>
      <Htag tag="h3" className="mb10">
        Do you and your ex have children?
      </Htag>

      <div className={s.form_oneline}>
        <div className={s.formField}>
          <RadioGroup row name="has_children" className={s.radioboxes}>
            <FormControlLabel
              value="no"
              checked={formik.values.has_children == "no"}
              control={<Radio color="default" onChange={updateInterview} />}
              label="No."
            />
            <FormControlLabel
              value="yes"
              control={<Radio color="default" onChange={updateInterview} />}
              label="Yes."
              checked={formik.values.has_children == "yes"}
            />
          </RadioGroup>
          {formik.touched.has_children && formik.errors.has_children && (
            <ErrorField error={formik.errors.has_children} />
          )}
        </div>
      </div>

      <Accordion className={s.desc} accordionData={accordionData} />
      <div className={s.groupButton}>
        <Button
          onClick={() => handleBack()}
          apearance="default"
          className={s.goBack}
          type="button"
        >
          Previous
        </Button>
        <Button type="submit" apearance="secondary">
          Next
        </Button>
      </div>
    </form>
  );
};
