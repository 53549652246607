import { useEffect, useState } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Box, IconButton } from "@mui/material";
import { NavLink, useLocation } from "react-router-dom";
import { Edit } from "@mui/icons-material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import s from "../SpecialSources.module.css";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { Htag } from "components";
import { get_system_guides } from "store/system_guides/system_guide-thunk";

import { getAllPCategories } from "store/categories/categories.thunk";
import { IGuide } from "store/system_guides/system_guide.interface";

export const ParentingGuides = () => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const system_guides: IGuide[] = useAppSelector(
    (state) => state.specialGuidesState.guides_db
  );

  const copy = async (id: string) => {
    await navigator.clipboard.writeText(
      `${process.env.REACT_APP_BASE_URL}/dashboard/guides/${id}`
    );
  };
  useEffect(() => {
    dispatch(get_system_guides(2));
    dispatch(getAllPCategories());
  }, [dispatch]);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      headerClassName: s.headerClassName,
      width: 70,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "unique_number",
      headerName: "Unique number",
      headerClassName: s.headerClassName,
      width: 120,
      sortable: true,
      disableColumnMenu: true,
    },
    {
      field: "title",
      headerClassName: s.headerClassName,
      headerName: "Title",
      sortable: true,
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: "step_id",
      headerClassName: s.headerClassName,
      headerName: "Divorce step",
      sortable: false,
      width: 170,
      disableColumnMenu: true,
    },
    {
      field: "action",
      headerName: `Action`,
      headerClassName: s.headerClassName,
      sortable: false,
      width: 110,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <div className={s.actions}>
            <NavLink to={`${pathname}/edit-guide/${params.row.id}`}>
              <IconButton aria-label="edit" color="info">
                <Edit />
              </IconButton>
            </NavLink>

            <ContentCopyIcon
              className={s.externalLink}
              onClick={() => copy(params.id.toString())}
            />
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Htag tag="h3">List of system guides</Htag>

      <Box sx={{ height: 838, width: "100%" }}>
        <DataGrid
          rows={system_guides}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { pageSize: 10, page: 0 },
            },
          }}
          className={s.tableWrap}
          pageSizeOptions={[10, 30, 50, 70, 100]}
        />
      </Box>
    </>
  );
};
