import React from "react";
import { StepCircle } from "../StepCircle/StepCircle.component";
import { NavLink } from "react-router-dom";
import s from "./TimeLine.module.css";
import { ITimeLineItem } from "./TimeLine.props";
import cn from "classnames";

const TimeLineItem = ({
  id,
  index,
  title,
  completed_percent,
  className,
}: ITimeLineItem) => {
  return (
    <div
      className={cn(
        s.timeLine_item,
        { [s.finishedItem]: completed_percent == 100 },
        className
      )}
    >
      <StepCircle
        className={cn(s.timeLine_number, {
          [s.finished]: completed_percent == 100,
        })}
        size="S"
        backgroundColor={"var(--third-logo-color)"}
      >
        {index}
      </StepCircle>
      <NavLink key={id} to={`/dashboard/divorceStep/${id}`}>
        {title}
      </NavLink>
    </div>
  );
};

export default TimeLineItem;
