import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import s from "./GuideArticle.module.css";
import { get_single_sguide } from "store/system_guides/system_guide-thunk";
import { ThreeDots } from "react-loader-spinner";
import { ArticleContent } from "components";
import { Page404 } from "pages/404/404.component";
import { IGuide } from "store/system_guides/system_guide.interface";

export const GuideArticle = () => {
  const { guideId } = useParams();

  const dispatch = useAppDispatch();

  const item: IGuide = useAppSelector((state) => {
    return state.specialGuidesState.guides_db.filter(
      (guide: IGuide) => guide.id == Number(guideId)
    )[0];
  });

  const loading = useAppSelector(
    (state) => state.specialGuidesState.loading
  );

  useEffect(() => {
    if (!guideId) return;
    if (!item || !Object.prototype.hasOwnProperty.call(item, "content")) {
      dispatch(get_single_sguide(guideId));
    }
  }, [dispatch]);

  return (
    <div className={s.article}>
      {loading ? (
        <ThreeDots wrapperClass={s.loader} />
      ) : (
        <>
          {item ? (
            <ArticleContent type="sguide" data={item}></ArticleContent>
          ) : (
            <Page404 className={s.componentNotFound} />
          )}
        </>
      )}
    </div>
  );
};
