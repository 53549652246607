import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { dragDrop } from "store/tasks/tasks-reducer";

import cn from "classnames";
import s from "./Boards.module.css";
import { Htag } from "components";
import { useEffect } from "react";
import styled from "@emotion/styled";
import { IBoard, ITask } from "store/tasks/tasks-interface";

import TaskItem from "components/Tasks/TaskItem/TaskItem.component";
import { setNewTaskStatus } from "store/divorceSteps/divorceSteps.thunk";
import { BoardsProps } from "./Boards.props";

const TaskList = styled.div``;

export const BoardsComponent = ({ boards }: BoardsProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const DragEnd = (result: DropResult) => {
    const { destination, source } = result;

    if (
      !destination ||
      (destination.droppableId === source.droppableId &&
        destination.index === source.index)
    ) {
      return;
    }

    const sourceColumn = boards[Number(source.droppableId)];
    const destColumn = boards[Number(destination.droppableId)];

    const newSourceTasks = Array.from(sourceColumn.tasks);
    let newDestTasks = Array.from(destColumn.tasks);
    const [draggedItem] = newSourceTasks.splice(source.index, 1);

    if (destination.droppableId !== source.droppableId) {
      newDestTasks = Array.from(destColumn.tasks);
    } else {
      newDestTasks = Array.from(newSourceTasks);
    }

    newDestTasks.splice(destination.index, 0, {
      ...draggedItem,
      status: Number(destination.droppableId),
    });

    const newSourceColumn = {
      ...sourceColumn,
      tasks: newSourceTasks,
    };

    const newDestColumn = {
      ...destColumn,
      tasks: newDestTasks,
    };

    dispatch(dragDrop(newSourceColumn));
    dispatch(dragDrop(newDestColumn));

    if (draggedItem.sort !== undefined) {
      dispatch(
        setNewTaskStatus({
          task_id: draggedItem.id,
          status: Number(destination.droppableId),
          sort: destination.index,
        })
      );
    }
  };

  return (
    <div className={s.boardWrap}>
      <DragDropContext onDragEnd={DragEnd}>
        {boards.map((board: IBoard) => (
          <div key={`board-${board.id}`} className={cn(s.tasksColumn)}>
            <Htag tag="h3" className={s.boardTitle}>
              {board.title}
            </Htag>
            <div className={s.tasks_content}>
              <Droppable droppableId={board.id.toString()}>
                {(provided) => (
                  <TaskList
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {board.tasks.map((x: ITask, index: number) => (
                      <TaskItem
                        key={x.id}
                        type={board.title}
                        task={x}
                        index={index}
                      />
                    ))}
                    {provided.placeholder}
                  </TaskList>
                )}
              </Droppable>
            </div>
          </div>
        ))}
      </DragDropContext>
    </div>
  );
};
