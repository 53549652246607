import { ArticleContentProps } from "./ArticleContent.props";
import ReactHtmlParser from "html-react-parser";
import cn from "classnames";
import s from "./ArticleContent.module.css";
import { Htag } from "../Htag/Htag.component";
import { ClockIcon, JournalIcon, TemplateIcon } from "../Icons/Icons.component";
import { AddBookmark } from "../AddBookmark/AddBookmark.component";
import { useAppSelector } from "../../utils/hooks";
import { FileProps } from "../../store/system_guides/system_guide.interface";
import axios from "axios";
import { DOWNLOAD_ATTACH } from "../../store/config";
import { useNavigate } from "react-router-dom";
import { downloadFile } from "utils/file.download";
import { getTypeArticle } from "utils/utils";
import { StackItemComponent } from "components/StackItem/StackItem.component";

export const ArticleContent = ({
  type,
  data,
}: ArticleContentProps): JSX.Element => {
  const { token } = useAppSelector((state) => state.userState);

  const navigate = useNavigate();

  const downloadAttach = (id: number, name: string) => {
    axios
      .get(DOWNLOAD_ATTACH(id), {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
        responseType: "blob",
      })
      .then((response) => {
        downloadFile(response.data, name);
      });
  };

  return (
    <article className={s.articleContent}>
      <Htag tag="h1" className={s.title}>
        {data?.title}
      </Htag>
      <div className={s.meta}>
        {data?.reading_time && (
          <div className={s.reding_time}>
            <ClockIcon /> {data?.reading_time}
          </div>
        )}

        {data?.need_journal && data?.need_journal !== 0 && (
          <div className={s.reding_time}>
            <JournalIcon className={s.JournalIcon} /> Journal
          </div>
        )}

        {data.attachments && data.attachments.length > 0 && (
          <div className={s.templatesMark}>
            <TemplateIcon /> Templates
          </div>
        )}

        {data && data.type !== "guide" && (
          <div className={s.saveMark}>
            <AddBookmark
              type={type}
              item={{
                title: data.title,
                id: data?.id,
                bookmark: data?.bookmark,
              }}
            />
          </div>
        )}
        <div className={s.typeMark}>
          {type ? getTypeArticle(type) : getTypeArticle(data.type)}
        </div>
      </div>

      <div className={cn(s.contentWrap)}>
        {typeof data?.content == "string" && ReactHtmlParser(data.content)}
      </div>
      {data.attachments && data?.attachments.length > 0 && (
        <div className={s.templates}>
          <h3>
            <TemplateIcon /> Templates to help you
          </h3>
          <div className={s.templates_wrap}>
            {data.attachments.map((template: FileProps, index: number) => (
              <StackItemComponent
                onClick={() => {
                  downloadAttach(template.id, template.name);
                }}
                key={index}
                data={{ title: template.name }}
              ></StackItemComponent>
            ))}
          </div>
        </div>
      )}
      {data.domestic_abuse && (
        <div className={cn(s.dometic_msg, "p26", s.contentWrap)}>
          {ReactHtmlParser(data.domestic_abuse)}
        </div>
      )}
      {window.history.length > 1 && (
        <span
          onClick={() => navigate(-1)}
          className={cn(s.backBtn, "hover-underline-animation_back")}
        >
          {"<"} Back
        </span>
      )}
    </article>
  );
};
