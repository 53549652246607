import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IBookmarks } from "./bookmark-interface";
import { IGuide } from "store/system_guides/system_guide.interface";

const initBookmarks = {
  wguides: [],
  sguides: [],
  cstudies: [],
  loading: false,
  error: "",
};

const bookmarksState = createSlice({
  name: "@@bookmarks",
  initialState: initBookmarks,
  reducers: {
    setBookmarks: (
      state: IBookmarks,
      action: PayloadAction<Partial<IGuide>[]>
    ) => {
      state.cstudies = [];
      state.sguides = [];
      state.wguides = [];
      action.payload.forEach((item: Partial<IGuide>) => {
        if (item.type == "sguide") {
          state.sguides.push(item);
        } else if (item.type == "cstudies") {
          state.cstudies.push(item);
        } else if (item.type == "wguides") {
          state.wguides.push(item);
        }
      });
    },
    removeFromList: (
      state: IBookmarks,
      action: PayloadAction<{
        type: string;
        id: number;
      }>
    ) => {
      if (action.payload.type == "sguide") {
        state.sguides = state.sguides.filter(
          (item) => item.id !== action.payload.id
        );
      } else if (action.payload.type == "cstudies") {
        state.cstudies = state.cstudies.filter(
          (item) => item.id !== action.payload.id
        );
      } else if (action.payload.type == "wguides") {
        state.wguides = state.wguides.filter(
          (item) => item.id !== action.payload.id
        );
      }
    },

    reset: () => initBookmarks,
  },
});

export const { reset, setBookmarks, removeFromList } = bookmarksState.actions;

export default bookmarksState.reducer;
