import { Checkbox, FormControlLabel } from "@mui/material";
import { Button, ErrorField, Htag } from "components";
import { useFormik } from "formik";
import s from "./TC.module.css";
import * as yup from "yup";
import { useAppDispatch } from "utils/hooks";
import { setShowCalendy } from "store/coaching/coaching.reducer";
import { NavLink } from "react-router-dom";
import Privacy from "./Privacy_Policy.pdf";
import Terms from "./Terms&Conditions.pdf";

export const validationSignupSchema = yup.object({
  terms: yup.bool().oneOf([true]),
});
export const TCFreeCallComponent = () => {
  const dispatch = useAppDispatch();

  const formik = useFormik({
    initialValues: {
      terms: false,
      //   showProfile: false,
    },
    validationSchema: validationSignupSchema,
    onSubmit: () => {
      dispatch(setShowCalendy(true));
    },
  });

  return (
    <>
      <Htag tag="h1">Terms and conditions</Htag>
      <form id="form-1" className={s.termsForm} onSubmit={formik.handleSubmit}>
        <p>
          We are not giving legal advice and our conversation won’t be covered
          by legal professional privilege, meaning that any communications
          between you and us could be produced as evidence in court proceedings
          if ordered by a court or other authority.
        </p>

        <div className={s.checkboxes}>
          <FormControlLabel
            control={
              <Checkbox
                name="terms"
                color="default"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            }
            label="I understand"
          />
          {!formik.isValid && (
            <ErrorField
              error={
                "To proceed you need to accept the terms and conditions of our divorce expert."
              }
            />
          )}
          <Button apearance="dark" type="submit">
            Continue
          </Button>
        </div>
      </form>
    </>
  );
};
