
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ClockIcon, Htag, GuideIcon, TemplateIcon } from "components";
import ReactHtmlParser from "html-react-parser";
import { useAppDispatch, useAppSelector } from "utils/hooks";

import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { loadSingleTask } from "store/tasks/tasks-thunk";
import s from "./SingleTask.module.css";
import cn from "classnames";

import { ThreeDots } from "react-loader-spinner";
import r from "components/ArticleContent/ArticleContent.module.css";
import { ITemplates, ITask } from "store/tasks/tasks-interface";

import { Page404 } from "../../pages/404/404.component";
import { downloadAttach } from "controlls/tasks-controll";
import { StackItemComponent } from 'components/StackItem/StackItem.component';
import { getArticleLink } from 'controlls/use-link';

export const SingleTask = () => {
  const { taskId } = useParams();
  const navigate = useNavigate();
  const { loading } = useAppSelector((state) => state.tasks);

  const dispatch = useAppDispatch();
  const id_link = taskId?.slice(taskId.indexOf("/") + 1) || "";
  const id = taskId?.slice(taskId.indexOf("=") + 1);

  const [task, setTask] = useState<ITask | null>(null);

  useEffect(() => {
    dispatch(loadSingleTask(`${id_link}`))
      .unwrap()
      .then((data) => {
        setTask(data);
      });
  }, []);

  return (
    <div>
      {loading ? (
        <ThreeDots wrapperClass={s.loader} />
      ) : task ? (
        <div className={cn(s.taskViewWrap)}>
          <header className={s.taskViewHeader}>
            <Htag tag="h2" className={r.title}>
              {task.title}
            </Htag>

            <div className={s.extraInfo}>
              {task?.reading_time && (
                <span>
                  <>
                    <ClockIcon /> {task?.reading_time}
                  </>
                </span>
              )}
              {task.attachments && task.attachments.length > 0 && (
                <span className={r.templatesMark}>
                  <>
                    <TemplateIcon /> Templates
                  </>
                </span>
              )}
            </div>
          </header>
          <div className={cn(s.task_desc)}>
            <Htag tag="h4">
              <GuideIcon /> Description
            </Htag>
            <div className={cn(s.task_desc_content, r.contentWrap)}>
              {task.description && ReactHtmlParser(task.description)}
            </div>
            {task.guides && task.guides.length > 0 && (
              <div className={r.templates}>
                {task.guides?.map((guide, index) => (
                  <StackItemComponent
                    key={index}
                    onClick={(e) => navigate(getArticleLink("guide", guide.id))}
                    className={s.attachmentContainer}
                    data={{
                      title: guide.title,
                      type: "guide",
                      reading_time: guide.reading_time,
                    }}
                  />
                ))}
              </div>
            )}

            {task.attachments && task?.attachments.length > 0 && (
              <div className={r.templates}>
                <h3>
                  <TemplateIcon /> Templates to help you
                </h3>
                {task.attachments.map((template: ITemplates, index: number) => (
                  <div
                    onClick={() => {
                      if (task.id) {
                        if (template.name)
                          downloadAttach(template.id, task.id, template.name);
                      }
                    }}
                    className={r.templateItem}
                    key={index}
                  >
                    <FileDownloadOutlinedIcon />
                    {template.name}
                  </div>
                ))}
              </div>
            )}
          </div>

          {window.history.length > 1 && (
            <span
              onClick={() => navigate(-1)}
              className={cn(s.backBtn, "hover-underline-animation_back")}
            >
              {"<"} Back
            </span>
          )}
        </div>
      ) : (
        <Page404 className={s.componentNotFound} />
      )}
    </div>
  );
};
