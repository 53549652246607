import { Button } from "components";
import { Htag } from "../../components/Htag/Htag.component";
import { useAppDispatch, useAppSelector } from "../../utils/hooks";
import s from "./404.module.css";
import { NotFoundProps } from "./404.props";
import cn from "classnames";
import { Link } from "react-router-dom";
import { openLoginModal } from "store/login/login-reducer";

export const Page404 = ({
  className,
  ...props
}: NotFoundProps): JSX.Element => {
  const { loading } = useAppSelector((state) => state.pages);
  const dispatch = useAppDispatch();
  return (
    <>
      {!loading && (
        <div className={cn(s.p404, className)} {...props}>
          <Htag tag="h1">The page you’re looking for isn’t available</Htag>
          <p>
            Sorry we can't find what you're looking for. It’s possible that the
            web address is incorrect, or the page may have been moved.
          </p>
          <div className={s.buttonGroup}>
            <Link to="/" onClick={() => dispatch(openLoginModal(false))}>
              <Button apearance="secondary">Go to our website</Button>
            </Link>
            <Link to="/">
              <Button
                apearance="secondary"
                onClick={() => dispatch(openLoginModal(true))}
              >
                Sign in to your account
              </Button>
            </Link>
          </div>
        </div>
      )}
    </>
  );
};
