import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { resetToDefault } from "../login/login-reducer";
import {
  get_single_sguide,
  get_system_guides,
  updateSystemGuide,
} from "./system_guide-thunk";
import { IGuide, ISystemGuides } from "./system_guide.interface";

const initialState: ISystemGuides = {
  guides: [],
  guides_db: [],
  error: "",
  loading: false,
};

const specialGuidesState = createSlice({
  name: "@@specialGuides",
  initialState,
  reducers: {
    addGuideId: (
      state: ISystemGuides,
      action: PayloadAction<string | string[]>
    ) => {
      if (typeof action.payload == "string") {
        state.guides.push(action.payload);
      } else {
        const toRemove = new Set(action.payload);
        state.guides = state.guides.filter((x) => !toRemove.has(x)) || [];
        state.guides = state.guides.concat(action.payload);
      }
    },
    removeGuideId: (
      state: ISystemGuides,
      action: PayloadAction<string | string[]>
    ) => {
      if (typeof action.payload == "string") {
        state.guides = state.guides.filter(
          (item) => item !== action.payload
        );
      } else {
        const toRemove = new Set(action.payload);

        state.guides = state.guides.filter((x) => !toRemove.has(x)) || [];
      }
    },
    resetGuidesIds: (state) => {
      state.guides = [];
    },
	
    saveBookmark: (state: ISystemGuides, action) => {
      state.guides_db = state.guides_db.map((item) => {
        if (item.id == action.payload) {
          item.bookmark = true;
        }
        return item;
      });
    },
    removeBookmark: (state: ISystemGuides, action) => {
      state.guides_db = state.guides_db.map((item) => {
        if (item.id == action.payload.id) {
          item.bookmark = false;
        }
        return item;
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(resetToDefault, () => {
        return initialState;
      })
      .addCase(
        get_system_guides.fulfilled,
        (state: ISystemGuides, action: PayloadAction<IGuide[]>) => {
          state.guides_db = action.payload;
        }
      )
      .addCase(
        get_single_sguide.fulfilled,
        (state: ISystemGuides, action: PayloadAction<IGuide>) => {
          state.guides_db = state.guides_db.filter(
            (page) => page.id !== action.payload.id
          );

          state.guides_db.push(action.payload);
        }
      )
      .addCase(
        updateSystemGuide.fulfilled,
        (state: ISystemGuides, action) => {
          state.guides_db = state.guides_db.map((x) => {
            if (x.id == action.payload.guide.id) {
              return action.payload.guide;
            }
            return x;
          });
        }
      )
      .addMatcher(
        (action) => action.type.endsWith("/pending"),
        (state) => {
          state.error = "";
          state.loading = true;
        }
      )

      .addMatcher(
        (action) => action.type.endsWith("/rejected"),
        (state, action) => {
          state.loading = false;

          if (action.payload) {
            state.error = action.payload;
          } else if (action.error.message) {
            state.error = action.error.message;
          } else {
            state.error = "Some server error";
          }
        }
      )
      .addMatcher(
        (action) => action.type.endsWith("/fulfilled"),
        (state) => {
          state.error = "";
          state.loading = false;
        }
      );
  },
});

export const {
  addGuideId,
  removeGuideId,
  removeBookmark,
  saveBookmark,
  resetGuidesIds,
} = specialGuidesState.actions;

export default specialGuidesState.reducer;
