import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { saveGOnboarding } from "../generalOnboarding/generalOnboarding-thunk";
import { resetToDefault } from "../login/login-reducer";

import {
  deleteProfile,
  getUserData,
  setUser,
  updateEmailSubscription,
  updateProfile,
} from "./user-thunk";
import { IOUserEntity, IUser } from "./user-interface";
import { IMemory } from "store/memory/memory-thunk";

const initialState: IOUserEntity = {
  user: {
    id: null,
    has_partner: false,
    local: false,
    privacy: false,
    terms: false,
    name: "",
    email: "",
    role: "user",
    subscription: false,
    verify: false,
    questionary: null,
    memory: [],
  },
  token: localStorage.getItem("userToken"),
  expired_at: "",
  error: "",
  loading: false,
};

const userEntityState = createSlice({
  name: "@@userEntity",
  initialState: initialState,
  reducers: {
    setUsedData: (state, action: PayloadAction<IUser>) => {
      state.user = { ...state.user, ...action.payload };
    },

    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    addMemory: (state, action: PayloadAction<IMemory>) => {
      state.user.memory.push(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(resetToDefault, () => {
        return { ...initialState, token: null };
      })

      .addCase(setUser.fulfilled, (state, action) => {
        state.user.role = action.payload.user.role;
        state.user.email = action.payload.user.email;
        state.token = action.payload.token;
        state.expired_at = action.payload.expired_at;
      })
      .addCase(saveGOnboarding.fulfilled, (state, action) => {
        state.user = { ...state.user, ...action.payload.user };
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        state.user = action.payload;
      })
      .addCase(getUserData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserData.fulfilled, (state, action) => {
        state.user = action.payload.user;

        state.loading = false;
      })
      .addCase(deleteProfile.fulfilled, () => {
        return { ...initialState, token: localStorage.getItem("userToken") };
      })
      .addCase(updateEmailSubscription.fulfilled, (state, action) => {
        state.user.subscription = action.payload.subscription;
      })

      .addMatcher(
        (action) => action.type.endsWith("/rejected"),
        (state, action) => {
          state.loading = false;

          if (action.payload) {
            state.error = action.payload;
          } else if (action.error.message) {
            state.error = `Server error:  ${action.error.message}`;
          }
        }
      )
      .addMatcher(
        (action) => action.type.endsWith("/fulfilled"),
        (state) => {
          state.error = "";
          state.loading = false;
        }
      );
  },
});

export const { setUsedData, setToken, addMemory } = userEntityState.actions;

export default userEntityState.reducer;
