import { NavLink, useNavigate } from "react-router-dom";
import { ClockIcon, Htag } from "components";
import { useAppSelector } from "utils/hooks";
import s from "./SearchResults.module.css";
import { useEffect } from "react";
import { getArticleLink } from "controlls/use-link";
import { getTypeArticle } from "utils/utils";

export const SearchResults = (): JSX.Element => {
  const { search_results, search } = useAppSelector((state) => state.search);
  const navigate = useNavigate();

  useEffect(() => {
    if (!search) navigate("/dashboard");
  }, []);
  return (
    <div className={s.searchWrap}>
      <Htag tag="h1" className="mb15">
        Search
      </Htag>
      <Htag tag="h3" className="mb15">
        Search results for "{search}"
      </Htag>
      {search_results.length ? (
        search_results.map((result, index) => (
          <NavLink
            to={getArticleLink(result.type, result.id, result.step_id)}
            key={index}
            className={s.resultBlockLink}
          >
            <div className={s.resultBlock}>
              <Htag tag="h4">{result.title}</Htag>

              {getTypeArticle(result.type) && (
                <div className={s.typeMark}>{getTypeArticle(result.type)}</div>
              )}

              {result.reading_time && (
                <span className={s.reading_time}>
                  <ClockIcon /> {result.reading_time}
                </span>
              )}
            </div>
          </NavLink>
        ))
      ) : (
        <>There no results</>
      )}
    </div>
  );
};
