import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ADD_WELLBEING_SYSTEM_GUIDE,
  GET_SINGLE_WELLBEING_GUIDE,
  GET_SYSTEM_WELLBEING_GUIDES,
} from "../config";
import { IInitState } from "../root-interface";

import { API_TYPE, getRequestFabric } from "../thunk-helper";
import { IGuide } from "store/system_guides/system_guide.interface";

export const loadSystemWGuides = createAsyncThunk<
  IGuide[],
  undefined,
  { getState: IInitState; rejectValue: string }
>(
  "@@wellbeing/getWSystemGuides",
  async function (_, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.GET,
      getState,
      GET_SYSTEM_WELLBEING_GUIDES(),
      rejectWithValue,
      dispatch
    );
  }
);

export const loadSingleWSGuide = createAsyncThunk<
  IGuide,
  number,
  { getState: IInitState; rejectValue: string }
>(
  "@@wellbeing/loadSingleWSGuide",
  async function (id, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.GET,
      getState,
      GET_SINGLE_WELLBEING_GUIDE(id),
      rejectWithValue,
      dispatch
    );
  }
);

export const add_wsystem_guides = createAsyncThunk<
  { success: string },
  string[],
  { getState: IInitState; rejectValue: string }
>(
  "@@wellbeing/systemGuideAdding",
  async function (unique_numbers, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.POST,
      getState,

      ADD_WELLBEING_SYSTEM_GUIDE(),
      rejectWithValue,
      dispatch,
      JSON.stringify({ unique_numbers: unique_numbers })
    );
  }
);
