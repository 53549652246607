import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { resetToDefault } from "../login/login-reducer";

import { ISystemTask, ISystemTasks } from "./system_tasks.interface";
import {
  get_task_by_admin,
  get_tasks_by_admin,
  update_task_by_admin,
} from "./system-tasks-thunk";

const initialState: ISystemTasks = {
  tasks: [],
  tasks_db: [],
  error: "",
  loading: false,
};

const specialTasksState = createSlice({
  name: "@@systemTasks",
  initialState,
  reducers: {
    addId: (state: ISystemTasks, action: PayloadAction<string | string[]>) => {
      if (typeof action.payload == "string") {
        state.tasks.push(action.payload);
      } else {
        const toRemove = new Set(action.payload);
        state.tasks = state.tasks.filter((x) => !toRemove.has(x)) || [];
        state.tasks = state.tasks.concat(action.payload);
      }
    },
    removeId: (
      state: ISystemTasks,
      action: PayloadAction<string | string[]>
    ) => {
      if (typeof action.payload == "string") {
        state.tasks = state.tasks.filter((item) => item !== action.payload);
      } else {
        const toRemove = new Set(action.payload);

        state.tasks = state.tasks.filter((x) => !toRemove.has(x)) || [];
      }
    },
    resetTasksIds: (state) => {
      state.tasks = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(resetToDefault, () => {
        return initialState;
      })

      .addCase(
        get_tasks_by_admin.fulfilled,
        (state: ISystemTasks, action: PayloadAction<ISystemTask[]>) => {
          state.tasks_db = action.payload;
        }
      )
      .addCase(get_task_by_admin.fulfilled, (state: ISystemTasks, action) => {
        state.tasks_db.push(action.payload);
      })
      .addCase(
        update_task_by_admin.fulfilled,
        (state: ISystemTasks, action) => {
          state.tasks_db = state.tasks_db.map((task) => {
            if (task.id == action.payload.task.id) {
              return action.payload.task;
            }
            return task;
          });
        }
      )
      .addMatcher(
        (action) => action.type.endsWith("/pending"),
        (state) => {
          state.error = "";
          state.loading = true;
        }
      )
      .addMatcher(
        (action) => action.type.endsWith("/rejected"),
        (state, action) => {
          state.loading = false;
          if (action.payload) {
            state.error = action.payload;
          } else if (action.error) {
            state.error = `Server error:  ${action.error.message}`;
          }
        }
      )
      .addMatcher(
        (action) => action.type.endsWith("/fulfilled"),
        (state) => {
          state.error = "";
          state.loading = false;
        }
      );
  },
});

export const { addId, removeId, resetTasksIds } = specialTasksState.actions;

export default specialTasksState.reducer;
