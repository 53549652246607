import { Stepper } from "../../components";

import { FirstScreen } from "./FirstScreen.component";

import cn from "classnames";
import s from "./GeneralOnboarding.module.css";
import { FormGB1 } from "./FormGB1.component";
import { FormGB2 } from "./FormGB2.component";
import { FormGB3 } from "./FormGB3.component";
import { FormGB4 } from "./FormGB4.component";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { FormGB5 } from "./FormGB5.component";
import { FormGB6 } from "./FormGB6.component";
import { useCallback, useEffect, useState } from "react";
import {
  setBoardingStep,
  setOnboarding,
  setPrevBoardingStep,
} from "store/generalOnboarding/generalOnboarding-reducer";
import { FormGB7 } from "./FormGB7.component";
import { FormGB8 } from "./FormGB8.component";
import { FormGB9 } from "./FormGB9.component";
import { FormGB10 } from "./FormGB10.component";
import { FormGB11 } from "./FormGB11.component";
import { FormGB12 } from "./FormGB12.component";
import { FormGB13 } from "./FormGB13.component";
import { IDebs } from "store/generalOnboarding/generalOnboarding-interface";
import { LastScreen } from "./LastScreen.component";
import { FormCB1 } from "./FormCB1.component";
import { FormCB2 } from "./FormCB2.component";
import { FormCB3 } from "./FormCB3.component";
import { FormCB4 } from "./FormCB4.component";
import { FormCB5 } from "./FormCB5.component";
import { QuestionnaireProps } from './Questionnaire.props';

export const GeneralOnboarding = ({ close }: { close: () => void }) => {
  const { generalOnboarding, activeStep } = useAppSelector(
    (state) => state.generalOnBoardingState
  );

  const [stepComponents, setStepComponents] = useState<
    {
      component: ({ handleNextStep }: QuestionnaireProps) => JSX.Element;
      extraProps?: any;
    }[]
  >([]);

  const dispatch = useAppDispatch();

  const handleBack = useCallback(
    (value = activeStep) => {
      dispatch(setPrevBoardingStep(value));
    },
    [activeStep, dispatch]
  );

  const handleNextStep = (value = activeStep) => {
    dispatch(setBoardingStep(value));
  };

  const onlyAdult = (): boolean => {
    if (
      !generalOnboarding.agesGroup.teenagers &&
      !generalOnboarding.agesGroup.toddlers &&
      !generalOnboarding.agesGroup.babies &&
      generalOnboarding.agesGroup.adults
    ) {
      return true;
    } else return false;
  };

  const setQuestionnaireData = (name: string, value: string | IDebs[]) => {
    dispatch(
      setOnboarding({
        ...generalOnboarding,
        [`${name}`]: value,
      })
    );
  };

  useEffect(() => {
    setStepComponents([
      { component: FirstScreen },
      { component: FormGB1 },
      { component: FormGB2 },

      { component: FormGB4 },
      { component: FormGB7 },
      { component: FormGB3 },

      ...(generalOnboarding.has_children == "yes"
        ? [
            { component: FormCB1 },
            { component: FormCB2 },
            ...(onlyAdult()
              ? []
              : [
                  { component: FormCB3 },
                  { component: FormCB4 },
                  { component: FormCB5 },
                ]),
          ]
        : []),

      { component: FormGB5 },
      { component: FormGB6 },

      { component: FormGB8 },
      { component: FormGB9 },
      { component: FormGB10 },
      { component: FormGB11 },
      { component: FormGB12 },
      { component: FormGB13 },
      { component: LastScreen, extraProps: { close } },
    ]);
  }, [generalOnboarding.has_children, generalOnboarding.agesGroup]);

  const stepContext = stepComponents.map(
    ({ component: StepComponent, extraProps = {} }, index) => (
      <StepComponent
        key={index}
        setData={setQuestionnaireData}
        handleBack={handleBack}
        handleNextStep={handleNextStep}
        {...extraProps}
      />
    )
  );
  return (
    <>
      <div className={cn(s.onboard_item)}>{stepContext[activeStep]}</div>

      {activeStep >= 0 && (
        <Stepper
          className={s.stepper}
          steps={stepContext.length}
          activeStep={activeStep + 1}
        />
      )}
    </>
  );
};
