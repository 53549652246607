import { CardProps } from "./Card.props";
import styles from "./Card.module.css";
import cn from "classnames";

export const Card = ({
  children,
  className,
  type,
  ...props
}: CardProps): JSX.Element => {
  return (
    <div
      className={cn(styles.Card, className, {
        [styles.guide_card]: type == "guide_card",
      })}
      style={{ backgroundColor: props.color }}
      {...props}
    >
      {children}
    </div>
  );
};
