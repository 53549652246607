import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { IExtraGuide } from "store/extra_guides/extra_guide.interface";
import { get_single_eguide } from "store/extra_guides/extra_guide-thunk";
import { ArticleContent } from "components";
import { Page404 } from "pages/404/404.component";
import s from "../../pages/Dashboard/GuideArticle/GuideArticle.module.css";

export const EGuideArticleComponent = () => {
  const { guideId } = useParams();

  const dispatch = useAppDispatch();

  const item: IExtraGuide = useAppSelector((state) => {
    return state.extraGuides.guides.filter(
      (guide: IExtraGuide) => guide.id == Number(guideId)
    )[0];
  });

  useEffect(() => {
    if (!guideId) return;
    if (!item || !Object.prototype.hasOwnProperty.call(item, "content")) {
      dispatch(get_single_eguide(guideId));
    }
  }, [dispatch]);

  return (
    <div className={s.article}>
      {item ? (
        <ArticleContent data={item} type="article"></ArticleContent>
      ) : (
        <Page404 className={s.componentNotFound} />
      )}
    </div>
  );
};
