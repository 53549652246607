import { createSlice } from "@reduxjs/toolkit";
import { resetToDefault } from "../login/login-reducer";
import {
  loadDivorceStep,
  loadDivorceSteps,
  setNewTaskStatus,
} from "./divorceSteps.thunk";

import { ITemplates, ITask } from "../tasks/tasks-interface";
import { IDivorceStep } from "./divorceSteps.interface";

export interface DivorceStepState {
  loading: boolean;
  error: string;
  templates: ITemplates[];
  tasks: ITask[];
  complete_percent: number;
}

export interface DivorceStepsState {
  loading: boolean;
  error: string;
  currentDivorceStep: IDivorceStep;
  divorceSteps: IDivorceStep[];
}

const initialState: DivorceStepsState = {
  divorceSteps: [],
  currentDivorceStep: {
    templates: [],
    tasks: [],
    complete_percent: 0,
    title: "",
    guides: [],
    sort: 0,
    id: 0,
  },
  loading: false,
  error: "",
};

const currentDivorceStep = createSlice({
  name: "currentDivorceStep",
  initialState,
  reducers: {
    updateDivorceStepCompleting: (state, action) => {
      state.divorceSteps = state.divorceSteps.map((step) => {
        if (step.id == action.payload.id) {
          step.complete_percent = action.payload.complete_percent;
        }
        return step;
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadDivorceSteps.fulfilled, (state, action) => {
        state.divorceSteps = action.payload;
      })
      .addCase(loadDivorceStep.fulfilled, (state, action) => {
        state.currentDivorceStep = action.payload;
      })
      .addCase(setNewTaskStatus.fulfilled, (state, action) => {
        state.currentDivorceStep.tasks = state.currentDivorceStep.tasks.map(
          (task: ITask) => {
            if (action.meta.arg.task_id == task.id) {
              task.status = action.meta.arg.status;
            }
            return task;
          }
        );
      })
      .addCase(resetToDefault, () => {
        return initialState;
      });
  },
});
export const { updateDivorceStepCompleting } = currentDivorceStep.actions;

export default currentDivorceStep.reducer;
