import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { resetToDefault } from "../login/login-reducer";
import { IExtraGuide, IExtraGuides } from "./extra_guide.interface";
import {
  addExtraGuide,
  deleteEGuide,
  get_all_extra_guides,
  get_single_eguide,
  updateExtraGuide,
} from "./extra_guide-thunk";

const initialState: IExtraGuides = {
  guides: [],
  error: "",
  loading: false,
};

const extraGuidesState = createSlice({
  name: "@@specialGuides",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(resetToDefault, () => {
        return initialState;
      })
      .addCase(
        get_all_extra_guides.fulfilled,
        (state: IExtraGuides, action: PayloadAction<IExtraGuide[]>) => {
          state.guides = action.payload;
        }
      )
      .addCase(
        get_single_eguide.fulfilled,
        (state: IExtraGuides, action: PayloadAction<IExtraGuide>) => {
          state.guides = state.guides.filter(
            (guide) => guide.id !== action.payload.id
          );

          state.guides.push(action.payload);
        }
      )
      .addCase(deleteEGuide.fulfilled, (state: IExtraGuides, action) => {
        state.guides = state.guides.filter(
          (guide) => guide.id !== action.payload.id
        );
      })
      .addCase(
        updateExtraGuide.fulfilled,
        (state: IExtraGuides, action) => {
          state.guides = state.guides.map((x) => {
            if (x.id == action.payload.guide.id) {
              return action.payload.guide;
            }
            return x;
          });
        }
      )

      .addMatcher(
        (action) => action.type.endsWith("/pending"),
        (state) => {
          state.error = "";
          state.loading = true;
        }
      )
      .addMatcher(
        (action) => action.type.endsWith("/rejected"),
        (state, action) => {
          state.loading = false;

          if (action.payload) {
            state.error = action.payload;
          } else if (action.error.message) {
            state.error = action.error.message;
          } else {
            state.error = "Some server error";
          }
        }
      )
      .addMatcher(
        (action) => action.type.endsWith("/fulfilled"),
        (state) => {
          state.error = "";
          state.loading = false;
        }
      );
  },
});

export default extraGuidesState.reducer;
