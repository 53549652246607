import s from "./GeneralOnboarding.module.css";
import { Button, Htag } from "components";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { useEffect } from "react";
import { addGuideId } from "store/system_guides/system_guide-reducer";
import { addWGuideId, removeWGuideId } from "store/wellbeing/wellbeing-reducer";
import cn from "classnames";
import { QuestionnaireProps } from "./Questionnaire.props";
import { setOnboarding } from "store/generalOnboarding/generalOnboarding-reducer";

const particular_concerns = [
  {
    title: "Positive co-parenting.",
    value: "pos_coparenting",
  },
  {
    title: "Behavioural issues that can be common when parents separate.",
    value: "behavioural",
  },
  {
    title: "My child’s reluctance to spend time with me or with my ex.",
    value: "child_reluctance",
  },
  {
    title: "Supporting children when they meet a new partner.",
    value: "supporting_children",
  },
  {
    title: "Making sure the children feel positive about both of us.",
    value: "positive_feeling",
  },
];

export const FormCB5 = ({
  handleBack,
  handleNextStep,
}: QuestionnaireProps): JSX.Element => {
  const { activeStep, generalOnboarding } = useAppSelector(
    (state) => state.generalOnBoardingState
  );

  const loadingSaveCQ = useAppSelector(
    (state) => state.questionnairesState.loading
  );
  const loadingTasks = useAppSelector(
    (state) => state.specialTasksState.loading
  );

  const getFormControlProps = (name: string) => ({
    control: (
      <Checkbox
        color="default"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          updateInterview(e)
        }
      />
    ),
    name,
    checked:
      generalOnboarding.particular_concerns[
        name as keyof typeof generalOnboarding.particular_concerns
      ],
  });

  const dispatch = useAppDispatch();

  const updateInterview = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = ev.target;

    if (checked) {
      switch (name) {
        case "pos_coparenting":
          dispatch(addWGuideId(["WR17", "WR17A"]));
          break;
        case "behavioural":
          dispatch(addWGuideId(["WR21"]));
          break;
        case "child_reluctance":
          dispatch(addWGuideId(["WR20"]));
          break;
        case "supporting_children":
          dispatch(addWGuideId(["WR18"]));
          break;
        case "positive_feeling":
          dispatch(addWGuideId(["WR20"]));

          break;
      }
    } else {
      switch (name) {
        case "pos_coparenting":
          dispatch(removeWGuideId(["WR17", "WR17A"]));
          break;
        case "behavioural":
          dispatch(removeWGuideId(["WR21"]));
          break;
        case "child_reluctance":
          dispatch(removeWGuideId(["WR20"]));
          break;
        case "supporting_children":
          dispatch(removeWGuideId(["WR18"]));
          break;
        case "positive_feeling":
          dispatch(removeWGuideId(["WR20"]));
          break;
      }
    }
    dispatch(
      setOnboarding({
        ...generalOnboarding,
        particular_concerns: {
          ...generalOnboarding.particular_concerns,
          [`${ev.target.name}`]: ev.target.checked,
        },
      })
    );
  };

  const submitHandle = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    handleNextStep?.();
  };

  return (
    <form id="form-7" onSubmit={submitHandle} className={s.form}>
      <span className={s.stepMark}>{`${activeStep} `}</span>

      <>
        <Htag tag="h3" className="mb15">
          We know that many parents have particular concerns about their
          children and co-parenting going forwards. Select the topics that
          interest you most.
        </Htag>
        <div className={cn(s.form_oneline, "mb15")}>
          <div className={s.formField}>
            <FormGroup>
              {particular_concerns.map((item, index) => (
                <FormControlLabel
                  {...getFormControlProps(item.value)}
                  key={index}
                  label={item.title}
                />
              ))}
            </FormGroup>
          </div>
        </div>
      </>

      <div className={s.groupButton}>
        <Button
          onClick={() => handleBack()}
          apearance="default"
          className={s.goBack}
          type="button"
        >
          Previous
        </Button>
        <Button
          type="submit"
          disabled={loadingSaveCQ || loadingTasks}
          apearance="secondary"
          className={cn({
            [s.disabled]: loadingSaveCQ || loadingTasks,
          })}
        >
          Next
        </Button>
      </div>
    </form>
  );
};
