import { useFormik } from "formik";

import * as yup from "yup";

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
} from "@mui/material";
import cn from "classnames";
import s from "../User.module.css";

import { Button, ErrorField } from "components";
import { useCallback, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { updateProfile } from "store/userInfo/user-thunk";
import { ProfileUpdate } from "store/userInfo/user-interface";

export const validationSignupSchema = yup.object({
  name: yup
    .string()
    .min(2, "Name should be at least 2 characters length")
    .required("Name is required"),
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
});

export const PersonalInfoForm = () => {
  const profile = useAppSelector((state) => state.userState.user);
  const [editable, setEditable] = useState(false);
  const { subscription } = useAppSelector((state) => state.accountState);
  const [updateFields, setUpdateFields] = useState<Partial<ProfileUpdate>>({});

  const dispatch = useAppDispatch();
  const babies = useRef<HTMLInputElement>(null);
  const toddlers = useRef<HTMLInputElement>(null);
  const teenagers = useRef<HTMLInputElement>(null);
  const editInfo = () => {
    setEditable(true);
  };
  const formik = useFormik({
    initialValues: profile,
    validationSchema: validationSignupSchema,
    onSubmit: () => {
      setEditable(false);

      dispatch(updateProfile(updateFields));
    },
  });
  const updateObject = (obj: any, name: string, value: string) => {
    const properties = name.split(".");
    let currentObject = obj;

    for (let i = 0; i < properties.length - 1; i++) {
      if (!currentObject[properties[i]]) {
        currentObject[properties[i]] = {};
      }
      currentObject = currentObject[properties[i]];
    }

    currentObject[properties[properties.length - 1]] = value;
  };

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;

      formik.handleChange(event);

      if (!name.includes("generalQuestionnary")) {
        setUpdateFields({
          ...updateFields,
          [`${name}`]: value,
        });
      } else {
        updateObject(updateFields, name, value);

        setUpdateFields(updateFields);
      }
    },
    [formik]
  );

  const findSubstring = (str: string) => {
    const regex = /\.([^.]+)$/;
    const match = str.match(regex);
    if (match) {
      return match[1];
    } else {
      return null;
    }
  };
  return (
    <>
      <form id="form-1" onSubmit={formik.handleSubmit} className={s.form}>
        <FormControl fullWidth className={s.formControl}>
          <label className={s.label}>Your Name</label>
          <input
            id="name"
            name="name"
            disabled={!editable}
            type="text"
            className={cn({
              [s.error_field]: formik.errors.name && formik.touched.name,
            })}
            placeholder="Name"
            value={formik.values.name || ""}
            onChange={(e) => handleInputChange(e)}
            onBlur={formik.handleBlur}
          />
          {formik.touched.name && formik.errors.name ? (
            <ErrorField error={formik.errors.name} />
          ) : null}
        </FormControl>
     {/*    {subscription.status == "active" && (
          <FormControl
            fullWidth
            className={cn(s.formControl, "disabledFormControl")}
          >
            <label className={s.label}>Connections abroad</label>

            <RadioGroup
              row
              name="questionnaires.generalQuestionnary.not_foreigner"
              className={s.radioboxes}
            >
              <FormControlLabel
                value="no"
                checked={formik.values.questionary?.not_foreigner == "no"}
                control={<Radio color="default" onChange={handleInputChange} />}
                label="No"
                disabled={!editable}
              />
              <FormControlLabel
                value="yes"
                checked={formik.values.questionary?.not_foreigner == "yes"}
                control={<Radio color="default" onChange={handleInputChange} />}
                label="Yes"
                disabled={!editable}
              />
            </RadioGroup>
          </FormControl>
        )} */}
        {subscription.status == "active" && (
          <FormControl
            fullWidth
            className={cn(s.formControl, "disabledFormControl")}
          >
            <label className={s.label}>Your living arrangements</label>

            <p>
              You are living{" "}
              {profile.questionary?.living_property == "same"
                ? "in same property"
                : "in different properties"}
            </p>
          </FormControl>
        )}
        {subscription.status == "active" && (
          <>
            <FormControl fullWidth className={s.formControl}>
              <label className={s.label}>Children</label>

              <p>
                You{" "}
                {formik.values.questionary?.has_children == "yes"
                  ? ""
                  : "don't"}{" "}
                have children together
              </p>
            </FormControl>

            {formik.values.questionary?.has_children == "yes" && (
              <FormControl fullWidth className={s.formControl}>
                <label className={s.label}>Parenting plan</label>
                <p>
                  You{" "}
                  {profile.questionary?.parenting_plan == "yes" ? "" : "don't"}{" "}
                  have a parenting plan
                </p>
              </FormControl>
            )}
          </>
        )}
        <div className={s.buttonGroup}>
          {!editable ? (
            <span onClick={editInfo} className={s.editBtn}>
              Edit
            </span>
          ) : (
            <Button apearance="disable" type="submit">
              Save
            </Button>
          )}
        </div>
      </form>
    </>
  );
};
