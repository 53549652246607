import { Alert, FormControl, Snackbar } from "@mui/material";
import { useFormik } from "formik";
import { Button, ErrorField, Htag } from "../../../components";
import { AddFiledIcon } from "../../../components/Icons/Icons.component";
import * as yup from "yup";

import { useAppDispatch, useAppSelector } from "../../../utils/hooks";
import s from "./WellbeingAdmin.module.css";
import { useEffect, useState } from "react";
import {
  addNewWCategory,
  updateCategory,
} from "store/wellbeing/wellbeing-thunk";

import { useParams } from "react-router-dom";
import { CategoriesPage } from "./caterory.props";
import { PageType } from "store/system_guides/system_guide.interface";
import useCategories from "./useCategory";

export const ManageNewCategory = ({ pageType }: CategoriesPage) => {
  const { id } = useParams();

  const ct = useCategories(pageType).filter((ct) => ct.id == id)[0];

  const dispatch = useAppDispatch();
  const validationCategoryAdd = yup.object({
    title: yup.string().required("Title is required"),
  });
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const formikAddCategory = useFormik({
    initialValues: { title: "" },
    validationSchema: validationCategoryAdd,
    onSubmit: (values) => {
      handleClick();
      if (!id) {
        dispatch(addNewWCategory({ title: values.title, type: pageType }));
        formikAddCategory.resetForm();
      } else {
        dispatch(
          updateCategory({
            id: Number(id),
            title: values.title,
            type: pageType,
          })
        );
      }
    },
  });

  const updateCategoryData = (e: React.ChangeEvent<HTMLInputElement>) => {
    formikAddCategory.handleChange(e);
  };

  useEffect(() => {
    if (!id) {
      formikAddCategory.setFieldValue("title", "");
      return;
    }
    if (ct.title) formikAddCategory.setFieldValue("title", ct.title);
  }, [id]);

  return (
    <>
      <div className={s.wellbeing_form}>
        <Htag tag="h3">{id ? "Edit" : "Add New"} category</Htag>
        <form onSubmit={formikAddCategory.handleSubmit}>
          <FormControl fullWidth>
            <input
              id="title"
              name="title"
              type="text"
              placeholder="Add category"
              value={formikAddCategory.values.title || ""}
              onChange={(e) => updateCategoryData(e)}
              onBlur={formikAddCategory.handleBlur}
            />
            {formikAddCategory.touched.title &&
              formikAddCategory.errors.title && (
                <ErrorField error={formikAddCategory.errors.title} />
              )}
          </FormControl>
          <Button apearance="secondary" type="submit">
            <AddFiledIcon />
            Save category
          </Button>
        </form>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Category {id ? "saved" : "added"}!
        </Alert>
      </Snackbar>
    </>
  );
};
