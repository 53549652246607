import { Alert, FormControl, Snackbar } from "@mui/material";
import { useEffect, useState } from "react";
import {
  Button,
  EditorComponent,
  ErrorField,
  Htag,
  InputFileComponent,
  InputImageComponent,
  TextArea,
} from "components";
import s from "../SpecialSources.module.css";
import { NavLink, useParams } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "utils/hooks";
import { useFormik } from "formik";
import { validationExtraCreateSchema } from "./AddExtraSources.component";
import {
  get_single_eguide,
  updateExtraGuide,
} from "store/extra_guides/extra_guide-thunk";
import { FileProps, IAttach } from "store/system_guides/system_guide.interface";
import { Guide } from "models/Guide.model";
import InputTags from "components/Tags/Tags.component";

export const EditERPage = () => {
  const { guideId } = useParams();
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const [currentAttachments, setAttachments] = useState<
    Partial<FileProps | IAttach>[]
  >([]);
  const [attachmentsOld, setOldAttachments] = useState<Partial<FileProps>[]>(
    []
  );

  const dispatch = useAppDispatch();

  const { error } = useAppSelector((state) => state.specialGuidesState);

  const formik = useFormik({
    initialValues: new Guide(),
    validationSchema: validationExtraCreateSchema,
    onSubmit: (values) => {
      if (!guideId) return;
      const updatedValue = formik.initialValues.formData(values);

      dispatch(
        updateExtraGuide({
          er_id: guideId,
          er_data: updatedValue,
        })
      )
        .unwrap()
        .then(() => {
          setOpenSnackbar(true);
          setAttachments([]);
        });
    },
  });

  useEffect(() => {
    if (!guideId) return;

    dispatch(get_single_eguide(guideId))
      .unwrap()
      .then((resp: any) => {
        setOldAttachments(resp.attachments);
        formik.setFieldValue("content", resp.content);

        formik.setFieldValue("title", resp.title);
        formik.setFieldValue("unique_number", resp.unique_number);
        formik.setFieldValue("reading_time", resp.reading_time);
        formik.setFieldValue("image", resp.image);
        formik.setFieldValue("tags", resp.tags);
        formik.setFieldValue("domestic_abuse", resp.domestic_abuse);
      });
  }, []);

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  const handleAtachments = (attachments: FileProps | IAttach) => {
    setAttachments((old) => [...old, attachments]);

    formik.setFieldValue("attachments", [...currentAttachments, attachments]);
  };

  return (
    <div className={s.formsWrap}>
      <Htag tag="h3">Edit guide: "{formik.values.title}" </Htag>

      <form onSubmit={formik.handleSubmit}>
        <div className={s.formField}>
          <Htag tag="h4">Title</Htag>
          <FormControl fullWidth className={s.title}>
            <input
              id="title"
              name="title"
              type="text"
              onBlur={formik.handleBlur}
              value={formik.values.title}
              onChange={formik.handleChange}
              placeholder="Title"
            />
            {formik.touched.title && formik.errors.title && (
              <ErrorField error={formik.errors.title} />
            )}
          </FormControl>
        </div>
        <div className={s.formField}>
          <Htag tag="h4">Reading time: </Htag>
          <FormControl fullWidth className={s.reading_time}>
            <input
              id="reading_time"
              name="reading_time"
              type="text"
              onBlur={formik.handleBlur}
              value={formik.values.reading_time}
              onChange={formik.handleChange}
              placeholder="Reading time"
            />
            {formik.touched.reading_time && formik.errors.reading_time && (
              <ErrorField error={formik.errors.reading_time} />
            )}
          </FormControl>
        </div>
        <div className={s.formField}>
          <Htag tag="h4">Unique number: </Htag>
          <FormControl fullWidth className={s.reading_time}>
            <input
              id="unique_number"
              name="unique_number"
              type="text"
              onBlur={formik.handleBlur}
              value={formik.values.unique_number}
              onChange={formik.handleChange}
              placeholder="Unique number"
              className={s.field_unique_number}
            />

            {formik.touched.unique_number && formik.errors.unique_number && (
              <ErrorField error={formik.errors.unique_number} />
            )}
          </FormControl>
        </div>
        <div className={s.formField}>
          <Htag tag="h4" className={s.headline}>
            Upload preview image
          </Htag>
          <InputImageComponent
            handleFiles={formik.setFieldValue}
            handleOnBlur={formik.setFieldValue}
            file={formik.values.image}
            id="image"
            name="image"
          />
        </div>
        <div className={s.formField}>
          <Htag tag="h4" className={s.headline}>
            Upload attachments
          </Htag>
          <InputFileComponent
            files={attachmentsOld}
            handleFiles={handleAtachments}
            id="editRP"
          />
        </div>
        <div className={s.wyswyg_editorWrap}>
          <EditorComponent
            value={formik.values.content}
            handleChange={(e) => formik.setFieldValue("content", e)}
          />

          {formik.touched.content && formik.errors.content && (
            <ErrorField error={formik.errors.content} />
          )}
        </div>
        <div className={s.formField}>
          <Htag tag="h4">Domestic Abuse:</Htag>

          {/*   <TextArea
            error={
              formik.touched.domestic_abuse && formik.errors.domestic_abuse
                ? formik.errors.domestic_abuse
                : ""
            }
            name="domestic_abuse"
            id="domestic_abuse"
            value={formik.values.domestic_abuse || ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="Type your text here, max 500 characters"
          /> */}
          <EditorComponent
            value={formik.values.domestic_abuse}
            handleChange={(e) => formik.setFieldValue("domestic_abuse", e)}
            name="domestic_abuse"
          />
        </div>
        <div>
          <Htag tag="h3">Add tags:</Htag>

          <InputTags
            hashtag={formik.values.tags ? formik.values.tags : []}
            handleTags={(data) => formik.setFieldValue("tags", data)}
          />
        </div>

        {error &&
          Array.isArray(error) &&
          error.map((item, index) => (
            <ErrorField
              key={index}
              error={`Server error: ${Object.values(item)[0]}`}
            />
          ))}
        {error && typeof error == "string" && <ErrorField error={error} />}

        <p className={s.previewLink}>
          <NavLink
            target="_blank"
            to={`/admin-dashboard/extra-guides/edit-guide/${guideId}/preview`}
          >
            Preview
          </NavLink>
        </p>

        <Button apearance="secondary" type="submit">
          Save
        </Button>
      </form>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Extra guide "{formik.values.title}" is updated!
        </Alert>
      </Snackbar>
    </div>
  );
};
