import { IArticle } from "../blog/blog.interfaces";
import { IError } from "../root-interface";

export enum PageType {
  step = 0,
  wellbeing = 1,
  parenting,
}

export type FileProps = {
  path: string;
  lastModified: number;
  slice: () => void;
  stream: () => void;
  text: () => void;
  arrayBuffer: ArrayBuffer;
  name: string;
  size: number;
  type: string;
  id: number;
};

export type IAttach = {
  file: FileProps;
  name?: string;
  type: string | number;
  id?: number;
};

export interface ISource {
  attachments: any;
  bookmark?: boolean;
}

export interface IGuide extends IArticle, ISource {
  type?: string;
  step_id?: number | null;
  image?: Blob | null | string;
  unique_number?: string;
  is_wellbeing_resource?: boolean;
  tags: string[];
  category_id?: number | null;
  domestic_abuse?: string | null;

  resource_id?: number; // check if it need after refactoring

  short_desc?: string; // check if it need after refactoring
}

export interface ISystemGuides {
  guides: string[];
  guides_db: IGuide[];
  error: string | IError;
  loading: boolean;
}
