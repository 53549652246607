import { Htag } from "components";

import s from "./Wellbeing.module.css";
import cn from "classnames";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { useEffect } from "react";
import { loadGuides } from "store/wellbeing/wellbeing-thunk";

import { getAllWCategories } from "store/categories/categories.thunk";
import { PageType } from "store/system_guides/system_guide.interface";
import ScrollGallery from "components/ScrollGallery/ScrollGallery.component";

export const WellBeingIndex = () => {
  const dispatch = useAppDispatch();

  const categories = useAppSelector((state) =>
    state.wellbeing.categories.map((ct) => {
      const updatedCt = {
        ...ct,
        guides: ct.guides?.map((gd) => {
          return { ...gd, type: "wguide" };
        }),
      };
      return updatedCt;
    })
  );

  useEffect(() => {
    dispatch(loadGuides());
    dispatch(getAllWCategories());
  }, [dispatch]);

  return (
    <div className={s.wellbeingWrap}>
      <Htag tag="h1" className="mb32">
        Prioritise your wellbeing
      </Htag>
      {categories.length > 0 &&
        categories.map((ct, index) => (
          <div key={index} className={cn(s.guidesWrap, "mb32")}>
            {" "}
            {ct.type == PageType.wellbeing &&
              ct.guides &&
              ct.guides.length > 0 && (
                <>
                  <Htag tag="h2">{ct.title}</Htag>
                  <ScrollGallery
                    data={ct.guides}
                    showTgas={true}
                    type="default"
                  />
                </>
              )}
          </div>
        ))}
    </div>
  );
};
