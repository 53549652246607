import { LoginProps } from "./Login.props";

import s from "./Login.module.css";

import { FormControl } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { useFormik } from "formik";
import { validationLoginSchema } from "validation/validation";

import cn from "classnames";
import { Button, Htag } from "components";

import { setUsedData } from "store/userInfo/userInfo-reducer";

import { setUser } from "store/userInfo/user-thunk";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { useState } from "react";
import { openLoginModal, openPassModal } from "store/login/login-reducer";

import {
  setBoardingStep,
  setRegModalStatus,
} from "store/onBoarding/onboarding-reducer";
import { PasswordField } from "components/PasswordField/PasswordField.component";
import { unlockBodyScroll } from 'layout/header/nav/Nav.component';

export const Login = ({ isFromNav = true, title }: LoginProps): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleForgotPass = (): void => {
    dispatch(openLoginModal(false));
    dispatch(openPassModal(true));
  };

  const handleCreateAccount = (): void => {
    dispatch(openLoginModal(false));
    dispatch(setRegModalStatus(true));
  };

  const [initLogin, setLogin] = useState({ email: "", password: "" });

  const serverError = useAppSelector((state) => state.userState.error);

  const formik = useFormik({
    initialValues: initLogin,
    validationSchema: validationLoginSchema,
    onSubmit: (values) => {
      dispatch(setUser(values))
        .unwrap()
        .then((resp) => {
          if (!resp.token) return;
          dispatch(openLoginModal(false));
          dispatch(setUsedData(resp.user));
          setLogin({ email: "", password: "" });
          unlockBodyScroll();
          localStorage.setItem("userToken", resp.token);

          if (resp.user.role == "admin" && isFromNav) {
            navigate("/admin-dashboard");
          } else if (resp.user.role == "user" && isFromNav) {
            navigate("/dashboard");
          }
          if (!resp.user.id) return;
          window.datahappy.trackEvent({
            userId: resp.user.id.toString(),
            eventName: "login",
            userTraits: {
              name: resp.user.name,
              email: resp.user.email,
            },
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  });

  const loginHeader = isFromNav ? (
    <>
      <Htag tag="h1" className={s.mb25}>
        {title ? title : "Log in"}
      </Htag>
      {title ? (
        <p className="mb10">
          For security reasons inactive sessions are automatically closed.
          Please sign-in again.
        </p>
      ) : (
        ""
      )}
    </>
  ) : (
    <p>Log in to your account or create a new account:</p>
  );

  const updateLoginData = (ev: React.ChangeEvent<HTMLInputElement>) => {
    formik.handleChange(ev);
  };

  const goToVerify = () => {
    dispatch(openLoginModal(false));
    dispatch(setRegModalStatus(true));
    dispatch(setBoardingStep(2));
  };

  return (
    <>
      <div className={s.loginWrapper}>
        <form onSubmit={formik.handleSubmit} className={s.form_group}>
          {loginHeader}
          <div className={s.form_oneline}>
            <FormControl fullWidth>
              <Htag tag="h4" className="mb10">
                Email address
              </Htag>
              <input
                id="email"
                name="email"
                type="text"
                className={cn("inputField mb10", {
                  [s.error_field]: formik.errors.email && formik.touched.email,
                })}
                autoComplete="off"
                value={formik.values.email || ""}
                onChange={(e) => updateLoginData(e)}
                onBlur={formik.handleBlur}
              />
              {formik.touched.email && formik.errors.email ? (
                <span className={s.errorText}>{formik.errors.email}</span>
              ) : null}
            </FormControl>

            <FormControl className={s.formField} fullWidth>
              <Htag tag="h4" className="mb10">
                Password
              </Htag>
              <PasswordField
                name="password"
                value={formik.values.password}
                handleChange={updateLoginData}
                handleBlur={formik.handleBlur}
              />
              {formik.touched.password && formik.errors.password ? (
                <span className={s.errorText}>{formik.errors.password}</span>
              ) : null}
            </FormControl>
          </div>
          {serverError && <span className={s.errorText}>{serverError}</span>}

          {serverError === "Sorry, your email is not verified" && (
            <p className={s.forget_pass}>
              <a
                onClick={goToVerify}
                className="hover-underline-animation_back"
              >
                Verify email
              </a>
            </p>
          )}

          <div className={s.buttonGroup}>
            <Button apearance="secondary" type="submit">
              Sign in
            </Button>
          </div>
          <p className={s.forget_pass}>
            <a
              className="hover-underline-animation_back"
              onClick={handleForgotPass}
            >
              Forgot password
            </a>{" "}
            <a
              className="hover-underline-animation_back"
              onClick={handleCreateAccount}
            >
              Create account
            </a>
          </p>
        </form>
      </div>
    </>
  );
};
