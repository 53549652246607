import {
  AddBlogPost,
  AddCaseStudies,
  AddPage,
  AddWGuide,
  AdminAnalytics,
  AdminDashboard,
  AllPages,
  BlogList,
  CaseStudyUser,
  EditCSPage,
  EditERPage,
  EditPage,
  EditPost,

  EditSourcePage,
  EditWSSourcePage,
  ListCaseStudies,
  ListExtraGuides,
  ListSpecialCaseStudies,

  PreviewWSource,
  QuestionnaireAnalytics,
  SubscriptionList,
  WaitingList,
  WellbeingGuidesList,
} from "pages";
import { RouteType } from "./config";
import { IconsPage } from "pages/Icons-page.component";
import DashboardPageLayout from "layout/DashboardPageLayout.component";
import { AddExtraGuide } from "pages/AdminDashboard/ExtraGuides/AddExtraSources.component";
import { TotalWidgetsView } from "widgets/totalWidgets/TotalWidgetsView.component";
import { GuideArticle } from "pages/Dashboard/GuideArticle/GuideArticle.component";

import { CategoriesList } from "pages/AdminDashboard/GuideCaterories/CategoriesList.component";

import { ParentingGuides } from "pages/AdminDashboard/Parenting/ParentingGuides.component";

import { ManageNewCategory } from "pages/AdminDashboard/GuideCaterories/ManageCategory.component";
import { PageType } from "store/system_guides/system_guide.interface";
import { ListSystemTasks } from "pages/AdminDashboard/SpecialTasks/ListST.component";
import { EditSTPage } from "pages/AdminDashboard/SpecialTasks/EditST.component";
import { PreviewTask } from "pages/AdminDashboard/SpecialTasks/PreviewTask.component";
import { ListSystemGuides } from 'pages/AdminDashboard/SpecialGuides/ListSG.component';
import { EditSRPage } from 'pages/AdminDashboard/SpecialGuides/EditSG.component';
import { EGuideArticleComponent } from 'components/ExtraArticles/GuideArticle.component';

export const adminSidebarRoutes: RouteType[] = [
  {
    element: <SubscriptionList />,
    state: "adminDashboard.index",
    path: "/admin-dashboard",
  },
  {
    path: "/icons",
    element: <IconsPage />,
    state: "adminIcons",
  },
  {
    element: <DashboardPageLayout />,
    state: "analytics",

    menuProps: {
      displayText: "Analytics",
    },
    child: [
      {
        element: <SubscriptionList />,
        path: "/admin-dashboard/users",
        state: "analytics.users",
        menuProps: {
          displayText: "All members",
        },
      },
      {
        element: <AdminAnalytics />,
        path: "/admin-dashboard/analytics",
        state: "analytics.index",
        menuProps: {
          displayText: "Analytics",
        },
      },
      {
        element: <QuestionnaireAnalytics />,
        path: "/admin-dashboard/analyticsQuestionnaires",
        state: "analytics",
        menuProps: {
          displayText: "Questionnares Data",
        },
      },
    ],
  },
  {
    element: <DashboardPageLayout />,
    state: "adminTasks",

    menuProps: {
      displayText: "Tasks",
    },
    child: [
      {
        element: <ListSystemTasks />,
        path: "/admin-dashboard/tasks",
        state: "adminTasks.tasks",
        menuProps: {
          displayText: "Tasks",
        },
      },
      {
        element: <EditSTPage />,
        path: "admin-dashboard/tasks/edit-task/:id",
        state: "adminTasks.tasks",
      },
      {
        element: <PreviewTask />,
        path: "/admin-dashboard/tasks/edit-task/:taskId/preview",
        state: "adminTasks.tasks",
      },
    ],
  },
  {
    element: <DashboardPageLayout />,
    state: "adminGuides",
    menuProps: {
      displayText: "Wellbeing guides",
    },
    child: [
      {
        element: <CategoriesList pageType={PageType.wellbeing} />,
        path: "/admin-dashboard/wellbeing/categories",
        state: "adminGuides.wCategories",
        menuProps: {
          displayText: "Categories",
        },
      },

      {
        element: <ManageNewCategory pageType={PageType.wellbeing} />,
        path: "/admin-dashboard/wellbeing/add-new-category/",
        state: "adminGuides.gCategories",
        menuProps: {
          displayText: "Add new category",
        },
      },
      {
        element: <ManageNewCategory pageType={PageType.wellbeing} />,
        path: "/admin-dashboard/wellbeing/categories/edit-category/:id",
        state: "adminGuides.gCategories",
      },
      {
        element: <WellbeingGuidesList />,
        path: "/admin-dashboard/wellbeing/guides",
        state: "adminGuides.wGuides",
        menuProps: {
          displayText: "Guides",
        },
      },

      {
        element: <EditWSSourcePage />,
        path: "/admin-dashboard/wellbeing/guides/edit-wpage/:id",
        state: "adminGuides.wellbeing.glist",
      },
      {
        element: <EditSourcePage />,
        path: "/admin-dashboard/wellbeing/guides/edit-page/:id",
        state: "adminGuides.wellbeing.glist",
      },
      {
        element: <PreviewWSource />,
        path: "/admin-dashboard/wellbeing/guides/edit-page/:id/preview/",
        state: "adminGuides.wellbeing.glist",
      },

      {
        element: <AddWGuide />,
        path: "/admin-dashboard/wellbeing/add-wellbeing-guide",
        state: "adminGuides.addWGuide",
        menuProps: {
          displayText: "Add wellbeing guide",
        },
      },
    ],
  },

  {
    element: <DashboardPageLayout />,
    state: "guides",
    menuProps: {
      displayText: "Guides",
    },
    child: [
      {
        element: <ListSystemGuides />,
        path: "/admin-dashboard/guides",
        state: "guides.list",
        menuProps: {
          displayText: "All guides",
        },
      },

      {
        element: <EditSRPage />,
        path: "/admin-dashboard/guides/edit-guide/:id",
        state: "guides.list",
      },
      {
        element: <GuideArticle />,
        path: "/admin-dashboard/guides/edit-guide/:guideId/preview",
        state: "guides.list",
      },
      {
        element: <ListExtraGuides />,
        path: "/admin-dashboard/extra-guides",
        state: "guides.extra-guides",
        menuProps: {
          displayText: "Extra guides",
        },
      },
      {
        element: <EditERPage />,
        path: "/admin-dashboard/extra-guides/edit-guide/:guideId",
        state: "guides.extra-guide",
      },
      {
        element: <AddExtraGuide />,
        path: "/admin-dashboard/add-guide",
        state: "guides.extra-guide",
        menuProps: {
          displayText: "Add extra guide",
        },
      },
      {
        element: <EGuideArticleComponent />,
        path: "/admin-dashboard/extra-guides/edit-guide/:guideId/preview",
        state: "guides.list",
      },
    ],
  },
  {
    element: <DashboardPageLayout />,
    state: "parenting",
    menuProps: {
      displayText: "Parenting guides",
    },
    child: [
      {
        element: <CategoriesList pageType={PageType.parenting} />,
        path: "/admin-dashboard/parenting/categories",
        state: "parenting.pCategories",
        menuProps: {
          displayText: "Categories",
        },
      },
      {
        element: <ManageNewCategory pageType={PageType.parenting} />,
        path: "/admin-dashboard/parenting/categories/edit-category/:id",
        state: "parenting.pCategories",
      },
      {
        element: <ManageNewCategory pageType={PageType.parenting} />,

        path: "/admin-dashboard/parenting/add-new",
        state: "parenting.category-new",
        menuProps: {
          displayText: "Add category",
        },
      },

      {
        element: <ParentingGuides />,
        path: "/admin-dashboard/parenting/guides",
        state: "parenting.list",
        menuProps: {
          displayText: "Guides",
        },
      },

      {
        element: <EditSRPage />,
        path: "/admin-dashboard/parenting/guides/edit-guide/:id",
        state: "parenting.list",
      },
    ],
  },
  {
    element: <DashboardPageLayout />,
    state: "blog",
    menuProps: {
      displayText: "Blog",
    },
    child: [
      {
        element: <BlogList />,
        path: "/admin-dashboard/blog",
        state: "blog",
        menuProps: {
          displayText: "All pages",
        },
      },
      {
        element: <EditPost />,
        path: "/admin-dashboard/blog/edit-page/:slug",
        state: "blog.edit-post",
      },
      {
        element: <AddBlogPost />,
        path: "/admin-dashboard/add-post",
        state: "blog.addNewPost",
        menuProps: {
          displayText: "Add new post",
        },
      },
    ],
  },

  {
    element: <DashboardPageLayout />,
    state: "caseStudies",
    menuProps: {
      displayText: "Case Studies",
    },
    child: [
      {
        element: <ListCaseStudies />,
        path: "/admin-dashboard/caseStudies/list",
        state: "caseStudies.list",
        menuProps: {
          displayText: "Case studies list",
        },
      },
      {
        element: <ListSpecialCaseStudies />,
        path: "/admin-dashboard/caseStudies/special_list",
        state: "caseStudies.special_list",
        menuProps: {
          displayText: "Special Case studies list",
        },
      },
      {
        element: <AddCaseStudies />,
        path: "/admin-dashboard/add-newCS",
        state: "caseStudies.new",
        menuProps: {
          displayText: "Add new case study",
        },
      },

      {
        element: <CaseStudyUser />,
        path: "/admin-dashboard/caseStudies/edit-page/:id/preview",
        state: "caseStudies.preview",
      },

      {
        element: <EditCSPage />,
        path: "/admin-dashboard/caseStudies/edit-page/:id",
        state: "caseStudies.edit-cs",
      },
    ],
  },
  {
    element: <DashboardPageLayout />,
    state: "subscribers",
    menuProps: {
      displayText: "Waiting list",
    },
    child: [
      {
        element: <WaitingList />,
        path: "/admin-dashboard/waiting-list",
        state: "subscribers.index",
        menuProps: {
          displayText: "Waiting List",
        },
      },
    ],
  },
  {
    element: <DashboardPageLayout />,
    state: "contentPages",
    menuProps: {
      displayText: "Content pages",
    },
    child: [
      {
        index: true,
        element: <AdminDashboard />,
        path: "/admin-dashboard/content-pages",
        state: "contentPages.index",
      },
      {
        path: "/admin-dashboard/all-pages",
        element: <AllPages />,
        state: "contentPages.allSimplePage",
        menuProps: {
          displayText: "All simple pages",
        },
      },
      {
        path: "/admin-dashboard/all-pages/edit-page/:id",
        element: <EditPage />,
        state: "contentPages.allSimplePage",
      },
      {
        path: "/admin-dashboard/add-page",
        element: <AddPage />,
        state: "contentPages.addPage",
        menuProps: {
          displayText: "Add new page",
        },
      },
      {
        path: "/admin-dashboard/widgets",
        element: <TotalWidgetsView />,
        state: "contentPages.widgets",
        menuProps: {
          displayText: "Widgets",
        },
      },
    ],
  },
];
