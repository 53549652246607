import { IGuide } from "store/system_guides/system_guide.interface";
import { ITask, ITemplates } from "../tasks/tasks-interface";

export const divorceSteps: readonly { value: number; label: string }[] = [
  {
    value: 1,
    label: "Get organised",
  },
  {
    value: 2,
    label: "Start legal process",
  },
  {
    value: 3,
    label: "Assess finances",
  },
  {
    value: 4,
    label: "Separate finances",
  },
  {
    value: 5,
    label: "Finish legal process",
  },
  {
    value: 6,
    label: "Post-divorce admin",
  },
];

export interface IDivorceStep {
  id: number;
  title: string;
  complete_percent: number;
  guides: IGuide[];
  sort: number;
  tasks: ITask[];
  templates: ITemplates[];
}
