import { createSlice } from "@reduxjs/toolkit";
import { resetToDefault } from "../login/login-reducer";
import { deleteTask, loadSingleTask, loadTasks } from "./tasks-thunk";
import { getBoards, getTamplates } from "./tasks-selectors";
import { ITask, ITState } from "./tasks-interface";
import { setNewTaskStatus } from "store/divorceSteps/divorceSteps.thunk";

const initialState: ITState = {
  tasks: [],
  boards: [],
  templates: [],
  loading: false,
  error: "",
};

const tasksState = createSlice({
  name: "@@tasks",
  initialState,
  reducers: {
    setBoards: (state, action) => {
      state.boards = getBoards(action.payload);
    },

    setTemplates: (state, action) => {
      state.templates = getTamplates(action.payload);
    },

    dragDrop: (state, action) => {
      state.boards = state.boards.map((b) => {
        if (b.id === action.payload.id) {
          return action.payload;
        }
        return b;
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(resetToDefault, () => {
        return initialState;
      })
      .addCase(loadTasks.pending, (state) => {
        state.boards = [];
        state.templates = [];
        state.loading = true;
      })

      .addCase(loadTasks.fulfilled, (state, action) => {
        state.boards = getBoards(state.tasks);
      })
      .addCase(loadSingleTask.pending, (state) => {
        state.loading = true;
      })
      .addCase(loadSingleTask.fulfilled, (state, action) => {
        state.boards = getBoards(state.tasks);
      })
      .addCase(deleteTask.fulfilled, (state, action) => {
        state.boards = state.boards.map((item) => {
          item.tasks = item.tasks.filter((task) => task.id !== action.meta.arg);
          return item;
        });
      })
      .addCase(setNewTaskStatus.fulfilled, (state, action) => {
        state.boards = state.boards.map((board, index) => {
          if (index == action.meta.arg.status) {
            state.boards[index].tasks.splice(Number(action.meta.arg.sort), 1, {
              ...action.payload,
              status: action.meta.arg.status,
            });
          } else {
            state.boards[index].tasks = state.boards[index].tasks.filter(
              (task) => task.id !== action.meta.arg.task_id
            );
          }

          return board;
        });
      })
      .addMatcher(
        (action) => action.type.endsWith("/rejected"),
        (state, action) => {
          state.loading = false;
          if (action.payload) {
            state.error = action.payload;
          } else if (action.error.message) {
            state.error = `Server error:  ${action.error.message}`;
          }
        }
      )
      .addMatcher(
        (action) => action.type.endsWith("/fulfilled"),
        (state) => {
          state.error = "";
          state.loading = false;
        }
      );
  },
});

export const { dragDrop, setBoards, setTemplates } = tasksState.actions;

export default tasksState.reducer;
