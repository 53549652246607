import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { resetToDefault } from "../login/login-reducer";
import { Questionaires } from "./questionnaires-interface";

const initialState: Questionaires = {
  cQuestionnaire: {
    agesGroup: {
      babies: false,
      toddlers: false,
      teenagers: false,
      adults: false,
    },
    adultGroup: {
      firstGroup: false,
      secondGroup: false,
    },
    agreed_arrangements: null,
    children_know: null,
    parenting_plan: null,
    particular_concerns: {
      pos_coparenting: false,
      behavioural: false,
      child_reluctance: false,
      supporting_children: false,
      positive_feeling: false,
      move_locations: false,
    },
  },

  activeStep: 0,
  server_error: "",
  loading: false,
};

const questionnairesState = createSlice({
  name: "@@questionnaires",
  initialState,
  reducers: {
    setBoardingStep: (state, action: PayloadAction<number>) => {
      state.activeStep = action.payload + 1;
    },
    setPrevBoardingStep: (state, action) => {
      state.activeStep =
        action.payload > 0 ? action.payload - 1 : (action.payload = 0);
    },
    resetActiveStep: (state) => {
      state.activeStep = 0;
    },

    setCQuestionnaire: (state, action) => {
      state.cQuestionnaire = action.payload;
    },

    addServerError: (state, action) => {
      state.server_error = action.payload;
    },
    setToInit: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetToDefault, () => {
      return initialState;
    });
  },
});

export const {
  setBoardingStep,
  setPrevBoardingStep,
  resetActiveStep,
  addServerError,
  setToInit,
  setCQuestionnaire,
} = questionnairesState.actions;

export default questionnairesState.reducer;
