import { combineReducers } from "redux";
import routeStateSlice from "./routeState/routeState";
import loginStateSlice from "./login/login-reducer";
import onRegistrationSlice from "./onBoarding/onboarding-reducer";
import userEntityStateSlice from "./userInfo/userInfo-reducer";
import subscriptionsStateSlice from "./prices/prices-reducer";
import onParentingSlice from "./parenting/parenting-reducer";
import tasksStateSlice from "./tasks/tasks-reducer";
import allPagesStateSlice from "./simplePages/simplePages-reducer";
import wellbeingStateSlice from "./wellbeing/wellbeing-reducer";
import accountSlice from "./userInfo/account-reducer";
import generalOnBoardingSlice from "./generalOnboarding/generalOnboarding-reducer";
import specialTasksSlice from "./system_tasks/system_tasks-reducer";
import specialGuidesSlice from "./system_guides/system_guide-reducer";
import questionnairesSlice from "./questionnaires/questionnaires.reducer";
import bookmarksSlice from "./bookmarks/bookmarks-reducer";
import extraGuidesSlice from "./extra_guides/extra_guide-reducer";
import waitingListSlice from "./waitingList/waitingList-reducer";
import searchSlice from "./search/search-reducer";
import blogPostsSlice from "./blog/blog.reducer";
import caseStudiesSlice from "./caseStudy/caseStudy.reducer";
import divorceStepsSlice from "./divorceSteps/divorceSteps.reducer";
import analyticsDataSlice from "./analytics/analytics.reducer";
import onCoachingSlice from "./coaching/coaching.reducer";
import IAtlaSlice from "./atla/atla-reducer";

export const rootReducer = combineReducers({
  onRegistration: onRegistrationSlice,
  onLogin: loginStateSlice,
  userState: userEntityStateSlice,
  routeState: routeStateSlice,
  subscriptions: subscriptionsStateSlice,
  parenting: onParentingSlice,
  pages: allPagesStateSlice,
  tasks: tasksStateSlice,
  wellbeing: wellbeingStateSlice,
  accountState: accountSlice,
  generalOnBoardingState: generalOnBoardingSlice,
  specialTasksState: specialTasksSlice,
  specialGuidesState: specialGuidesSlice,
  questionnairesState: questionnairesSlice,
  bookmarksState: bookmarksSlice,
  extraGuides: extraGuidesSlice,
  waitingList: waitingListSlice,
  search: searchSlice,
  blogPostsState: blogPostsSlice,
  caseStudiesState: caseStudiesSlice,
  divorceStepsState: divorceStepsSlice,
  analyticsDataState: analyticsDataSlice,
  onCoachingState: onCoachingSlice,
  IAtlaState: IAtlaSlice,
});
