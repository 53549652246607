import { Alert, FormControl, Snackbar } from "@mui/material";
import {
  Button,
  EditorComponent,
  ErrorField,
  Htag,
  InputImageComponent,
  GuideIcon,
} from "components";
import { useFormik } from "formik";

import s from "./CaseStudies.module.css";

import { useState } from "react";
import { CSSummary } from "widgets/caseStudySummary/caseStudySummary.component";
import { OptionsWidget } from "./Options/OptionsWidget.component";
import { useAppDispatch } from "utils/hooks";
import { ExtraCaseStudy } from "widgets/extraCS/ExtraCaseStudy.component";
import { add_cs } from "store/caseStudy/caseStudy_thunk";
import { IOption, extraCases } from "store/caseStudy/caseStudy.interface";
import { validationCaseStudySchema } from "./CSValidation";
import { CaseStudy } from "models/CaseStudy.model";
import { formData } from "utils/utils";
export const AddCaseStudies = () => {
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const [serverError, setServerError] = useState<any[]>([]);

  const formik = useFormik({
    initialValues: new CaseStudy(),
    validationSchema: validationCaseStudySchema,
    onSubmit: (values, actions) => {
      const formaValue = formData(values);
      dispatch(add_cs(formaValue))
        .unwrap()
        .then(() => {
          formik.resetForm();
          setOpen(true);
          setServerError([]);
          formik.setFieldValue("options", []);
        })
        .catch((err) => {
          if (err && Array.isArray(err)) {
            setServerError(err);
          } else {
            setServerError([{ serverError: "Something wrong with server" }]);
          }
        });
    },
  });

  const handleOptions = (newOption: IOption) => {
    const arr: IOption[] | undefined[] | null[] = formik.values.options;
    arr[newOption.id] = newOption;
    formik.setFieldValue("options", arr);
  };

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <div className={s.csForm}>
      <Htag tag="h3">
        <GuideIcon /> Add new case study
      </Htag>

      <form onSubmit={formik.handleSubmit}>
        <div className={s.formField}>
          <Htag tag="h4">Title</Htag>
          <FormControl fullWidth className={s.title}>
            <input
              id="title"
              name="title"
              onBlur={formik.handleBlur}
              value={formik.values.title}
              type="text"
              onChange={formik.handleChange}
              placeholder="Title"
            />
            {formik.touched.title && formik.errors.title && (
              <ErrorField error={formik.errors.title} />
            )}
          </FormControl>
        </div>

        <div className={s.formField}>
          <Htag tag="h3">Subtitle</Htag>
          <FormControl fullWidth className={s.title}>
            <input
              id="subtitle"
              name="subtitle"
              onBlur={formik.handleBlur}
              value={formik.values.subtitle}
              type="text"
              onChange={formik.handleChange}
              placeholder="Title"
            />
            {formik.touched.title && formik.errors.title && (
              <ErrorField error={formik.errors.title} />
            )}
          </FormControl>
        </div>

        <div className={s.formField}>
          <Htag tag="h4">Reading time: </Htag>
          <FormControl className={s.reading_time}>
            <input
              id="reading_time"
              name="reading_time"
              type="text"
              onBlur={formik.handleBlur}
              value={formik.values.reading_time}
              onChange={formik.handleChange}
              placeholder="Reading time"
            />
            {formik.touched.reading_time && formik.errors.reading_time && (
              <ErrorField error={formik.errors.reading_time} />
            )}
          </FormControl>
        </div>
        <div className={s.formField}>
          <Htag tag="h4" className={s.headline}>
            Upload preview image
          </Htag>
          <InputImageComponent
            handleFiles={formik.setFieldValue}
            handleOnBlur={formik.setFieldValue}
            file={formik.values.image}
            onBlur={formik.handleBlur}
            id="image"
            name="image"
          />
        </div>
        <CSSummary
          summary={formik.values.summary}
          handleSummary={(summary) => formik.setFieldValue("summary", summary)}
        />

        <div className={s.wyswyg_editorWrap}>
          <EditorComponent
            value={formik.values.content}
            handleChange={(e) => formik.setFieldValue("content", e)}
          />

          {formik.touched.content && formik.errors.content && (
            <ErrorField error={formik.errors.content} />
          )}
        </div>

        <Htag tag="h4">Options</Htag>
        <div className={s.options}>
          <OptionsWidget
            data={formik.values.options[0]}
            handleOptions={handleOptions}
            id="0"
          />
          <OptionsWidget
            data={formik.values.options[1]}
            handleOptions={handleOptions}
            id="1"
          />
          <OptionsWidget
            data={formik.values.options[2]}
            handleOptions={handleOptions}
            id="2"
          />
        </div>
        <div className={s.widgetBlock}>
          <Htag tag="h4">Problems</Htag>
          <div className={s.ecs_list}>
            {extraCases.map((item, index) => (
              <ExtraCaseStudy
                index={index}
                key={index}
                title={item}
                data={formik.values.problems}
                handleOptions={(e) => formik.setFieldValue("problems", e)}
              />
            ))}
          </div>
        </div>
        {serverError
          ? serverError.map((item, index) => (
              <ErrorField
                key={index}
                error={Object.entries(item).map((a) => a.join(": "))[0]}
              />
            ))
          : null}

        <Button apearance="secondary" type="submit">
          Save
        </Button>
      </form>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Case study {formik.values.title} is added successfully!
        </Alert>
      </Snackbar>
    </div>
  );
};
