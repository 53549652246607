export interface ISummary {
  id: number;
  value: string;
}

export interface IOption {
  id: number;
  title: string;
  pros: string[];
  cons: string[];
  content: string;
}
export interface guideLink {
  title: string;
  url: string;
}

export interface IIssue {
  title: string;
  content: string;
  problem_id: number;
}

export interface ICaseStudies {
  id?: number;
  title: string;
  subtitle: string;
  guide_id?: number;
  summary: ISummary[];
  content: string;
  options: IOption[] | undefined[] | null[];
  problems: IIssue[];
  image?: Blob | null | string;
  unique_number?: string;
  reading_time: string;
  bookmark: boolean;
}

export interface CaseStudiesSlice {
  caseStudiesArray: ICaseStudies[];
  caseStudies_db: string[];
  error: string;
}

export const extraCases = [
  "Significant debt",
  "Inheritance",
  "Reckless spending",
  "New partner",
  "Post-separation money",
  "Self-employed",
  "Redundancy",
  "Infidelity",
  "Promotion at work",
];
