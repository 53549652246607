import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { resetToDefault } from "../login/login-reducer";
import { Blog, IGetPostsData } from "./blog.interfaces";
import { deletePost, get_all_posts } from "./blog-thunk";

const initialState: Blog = {
  posts: [],
  currentPage: 0,
  userPosts: [],
  error: "",
  loading: false,
};

const blogPostsState = createSlice({
  name: "@@specialGuides",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(resetToDefault, () => {
        return initialState;
      })

      .addCase(
        get_all_posts.fulfilled,
        (state: Blog, action: PayloadAction<IGetPostsData>) => {
          state.posts = action.payload.data;

          if (
            action.payload.meta.current_page > state.currentPage 
          ) {
            state.userPosts = [...state.userPosts, ...action.payload.data];
            state.currentPage = action.payload.meta.current_page;
          } 

        }
      )
      .addCase(deletePost.fulfilled, (state, action) => {
        state.posts = state.posts.filter(
          (post) => post.id != action.payload.page_id
        );
        state.userPosts = state.userPosts.filter(
          (post) => post.id != action.payload.page_id
        );
      })

      .addMatcher(
        (action) => action.type.endsWith("/pending"),
        (state) => {
          state.error = "";
          state.loading = true;
        }
      )
      .addMatcher(
        (action) => action.type.endsWith("/rejected"),
        (state, action) => {
          state.loading = false;

          if (action.payload) {
            state.error = action.payload;
          } else if (action.error.message) {
            state.error = action.error.message;
          } else {
            state.error = "Some server error";
          }
        }
      )
      .addMatcher(
        (action) => action.type.endsWith("/fulfilled"),
        (state) => {
          state.error = "";
          state.loading = false;
        }
      );
  },
});
// export const { createPost } = blogPostsState.actions;

export default blogPostsState.reducer;
