import { IParenting } from "./parenting-interface";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { IInitState } from "../root-interface";
import { GET_PARENTING_SPACE } from "../config";
import { getRequestFabric, API_TYPE } from "../thunk-helper";

export const loadParenting = createAsyncThunk<
  IParenting,
  undefined,
  { getState: IInitState; rejectValue: string }
>(
  "@@parenting/loadParenting",
  async function (_, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.GET,
      getState,
      GET_PARENTING_SPACE(),
      rejectWithValue,
      dispatch
    );
  }
);
