import { FileProps, IAttach } from "store/system_guides/system_guide.interface";
import { IBoard, ITemplates, ITask } from "./tasks-interface";

export enum Statuses {
  TODO = 0,
  DONE = 1,
}

export const nowT = (state: ITask[]) =>
  state.filter((task) => task.status == Statuses.TODO).sort(sortArr);
export const nextT = (state: ITask[]) =>
  state.filter((task) => task.status == Statuses.DONE).sort(sortArr);

const sortArr = (a: ITask, b: ITask) => {
  if (!a.sort || !b.sort) return 0;
  if (a.sort > b.sort) {
    return 1;
  }
  if (a.sort < b.sort) {
    return -1;
  }

  return 0;
};

export const getBoards = (state: ITask[]) => {
  const boards: IBoard[] = [
    {
      id: 0,
      title: "Tasks",
      tasks: nowT(state),
    },
    {
      id: 1,
      title: "Completed tasks",
      tasks: nextT(state),
    },
  ];

  return boards;
};

export const getTamplates = (tasks: ITask[]) => {
  return tasks
    .map((task) => {
      if (!task.attachments) return [];
      return task.attachments.map((attach: ITemplates) => {
        if (task.id) {
          return {
            ...attach,
            task_id: task.id,
          };
        } else {
          return {
            ...attach,
            task_id: task.id,
          };
        }
      });
    })
    .flat(1);
};
