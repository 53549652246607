import s from "./Icons-page.module.css";
import {
  AccountIcon,
  AddFiledIcon,
  Alarm,
  AlarmActiveIcon,
  AlarmCompletedIcon,
  AlarmLaterIcon,
  AlarmSimpleIcon,
  ArrowLeftIcon,
  ArrowUpIcon,
  AssetsIcon,
  AttachIcon,
  BookmarkIcon,
  Challenges,
  Checked_folderIcon,
  CheckIcon,
  ChildrenIcon,
  ClockIcon,
  CloseIcon,
  CommentIcon,
  CustomerStories,
  DefaultCheckIcon,
  DeleteIcon,
  DivisonIcon,
  DropDownArrowIcon,
  DuplicateIcon,
  Emotions,
  Features,
  Flag,
  GetItTeam,
  Help,
  HiwTitle,
  HowIcon,
  Icon1,
  Icon2,
  Icon3,
  IncomeIcon,
  JournalIcon,
  KeepIn,
  KeepInSmall,
  LeftTriangleIcon,
  Newsletter,
  NoImgIcon,
  OurPurpose,
  OurPurpose2,
  ParentsIcon,
  PencilIcon,
  ProfIcon,
  GuideIcon,
  RotatingArrow,
  ScholarHat,
  SearchIcon,
  SettingsIcon,
  SidebarCollapseIcon,
  Squar_round,
  StarDeformate,
  StarIcon,
  Supported,
  TeamBig,
  TeamIcon,
  TemplateIcon,
  Unsubscribe,
  WellbeingIcon,
  WellbeingImg,
} from "components";

export const IconsPage = () => {
  return (
    <div className={s.container}>
      <div>
        <Alarm /> "Alarm"
      </div>
      <div>
        <AlarmActiveIcon /> "AlarmActiveIcon"
      </div>
      <div>
        <AlarmLaterIcon /> "AlarmLaterIcon"
      </div>
      <div>
        <AlarmCompletedIcon /> "AlarmCompletedIcon"
      </div>
      <div>
        <AddFiledIcon /> "AddFiledIcon"
      </div>
      <div>
        <AttachIcon /> "AttachIcon"
      </div>
      <div>
        <CommentIcon /> "CommentIcon"
      </div>

      <div>
        <SearchIcon /> "SearchIcon"
      </div>
      <div>
        <DeleteIcon /> "DeleteIcon"
      </div>
      <div>
        <DuplicateIcon /> "DuplicateIcon"
      </div>
      <div>
        <ArrowLeftIcon /> "ArrowLeftIcon"
      </div>
      <div>
        <CheckIcon /> "CheckIcon"
      </div>

      <div>
        <LeftTriangleIcon /> "LeftTriangleIcon"
      </div>
      <div>
        <DropDownArrowIcon /> "DropDownArrowIcon"
      </div>
      <div>
        <SidebarCollapseIcon /> "SidebarCollapseIcon"
      </div>
      <div>
        <SettingsIcon /> "SettingsIcon"
      </div>
      <div>
        <GuideIcon /> "GuideIcon"
      </div>
      <div>
        <ArrowUpIcon /> "ArrowUpIcon"
      </div>
      <div>
        <CloseIcon /> "CloseIcon"
      </div>
      <div>
        <StarIcon /> "StarIcon"
      </div>
      <div>
        <AccountIcon /> "AccountIcon"
      </div>
      <div>
        <Checked_folderIcon /> "Checked_folderIcon"
      </div>
      <div>
        <ClockIcon /> "ClockIcon"
      </div>
      <div>
        <AlarmSimpleIcon /> "AlarmSimpleIcon"
      </div>
      <div>
        <DefaultCheckIcon /> "DefaultCheckIcon"
      </div>
      <div>
        <TemplateIcon /> "TemplateIcon"
      </div>
      <div>
        <BookmarkIcon /> "BookmarkIcon"
      </div>
      <div>
        <ScholarHat /> "ScholarHat"
      </div>
      <div>
        <JournalIcon /> "JournalIcon"
      </div>
      <div>
        <PencilIcon /> "PencilIcon"
      </div>
      <div>
        <NoImgIcon /> "NoImgIcon"
      </div>
      <div>
        <ParentsIcon /> "ParentsIcon"
      </div>
      <div>
        <DivisonIcon /> "DivisonIcon"
      </div>
      <div>
        <IncomeIcon /> "IncomeIcon"
      </div>
      <div>
        <AssetsIcon /> "AssetsIcon"
      </div>
      <div>
        <ChildrenIcon /> "ChildrenIcon"
      </div>
      <div>
        <WellbeingIcon /> "WellbeingIcon"
      </div>

      <div>
        <Icon1 /> "Icon1"
      </div>
      <div>
        <Icon2 /> "Icon2"
      </div>
      <div>
        <Icon3 /> "Icon3"
      </div>

      <div>
        <KeepInSmall /> "KeepIn_small"
      </div>
      <div>
        <KeepIn /> "KeepIn"
      </div>
      <div>
        <Challenges /> "Challenges"
      </div>
      <div>
        <Emotions /> "Emotions"
      </div>
      <div>
        <WellbeingImg /> "WellbeingImg"
      </div>
      <div>
        <OurPurpose /> "OurPurpose"
      </div>
      <div>
        <OurPurpose2 /> "OurPurpose2"
      </div>
      <div>
        <StarDeformate /> "StarDeformate"
      </div>
      <div>
        <CustomerStories /> "CustomerStories"
      </div>
      <div>
        <Features /> "Features"
      </div>
      <div>
        <Help /> "Help"
      </div>
      <div>
        <Newsletter /> "Newsletter"
      </div>
      <div>
        <ProfIcon /> "ProfIcon"
      </div>
      <div>
        <RotatingArrow /> "Rotating_arrow"
      </div>
      <div>
        <Supported /> "Supported"
      </div>
      <div>
        <TeamIcon /> "TeamIcon"
      </div>
      <div>
        <HiwTitle /> "HiwTitle"
      </div>
      <div>
        <HowIcon /> "HowIcon"
      </div>
      <div>
        <TeamBig /> "TeamBig"
      </div>
      <div>
        <GetItTeam /> "GetItTeam"
      </div>
      <div>
        <Unsubscribe /> "Unsubscribe"
      </div>
      <div>
        <Flag /> "Flag"
      </div>
      <div>
        <Squar_round /> "Squar_round"
      </div>
    </div>
  );
};
