import s from "./GeneralOnboarding.module.css";

import { Htag } from "components";


import { useEffect } from "react";
import { ThreeDots } from "react-loader-spinner";
import { QuestionnaireProps } from './Questionnaire.props';

export const LastScreen = ({ close }: QuestionnaireProps) => {
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      close?.();
    }, 3000);
  }, []);

  return (
    <div className={s.firstSlide}>
      <ThreeDots />
      <Htag tag="h3">Take a breath. You've answered all our questions.</Htag>
      <p>We're now creating your personalised dashboard.</p>
    </div>
  );
};
