import {
  Alert,
  FormControl,
  MenuItem,
  Snackbar,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import s from "./AddWGuide.module.css";
import {
  Button,
  ErrorField,
  Htag,
  InputImageComponent,
} from "../../../../components";
import { GuideIcon } from "../../../../components/Icons/Icons.component";
import { useAppDispatch, useAppSelector } from "../../../../utils/hooks";

import { validationCreateSchema } from "../../validation";
import { useFormik } from "formik";
import { EditorComponent } from "../../../../components/Editor/Editor.component";
import {
  FileProps,
  IAttach,
} from "../../../../store/system_guides/system_guide.interface";
import { InputFileComponent } from "../../../../components/InputFile/InputFile.component";

import { addWGuide } from "store/wellbeing/wellbeing-thunk";

import { divorceSteps } from "store/divorceSteps/divorceSteps.interface";
import { commonSelectProps } from "./EditSources.component";
import { getAllWCategories } from "store/categories/categories.thunk";
import { Category } from "store/categories/categories.interface";
import { Guide } from "models/Guide.model";

export const AddWGuide = () => {
  const dispatch = useAppDispatch();
  const categories: Category[] = useAppSelector(
    (state) => state.wellbeing.categories
  );

  const [currentGuideAttachments, setGuideAttachments] = useState<
    Partial<FileProps | IAttach>[]
  >([]);

  const [open, setOpen] = useState(false);
  // Add Guide

  const formik = useFormik({
    initialValues: new Guide(),
    validationSchema: validationCreateSchema,
    onSubmit: (values) => {
      const updatedValue = formik.initialValues.formData(values);
      dispatch(addWGuide(updatedValue))
        .unwrap()
        .then(() => {
          formik.resetForm();
          setGuideAttachments([]);
          setOpen(true);
        });
    },
  });

  const handleAtachments = (attachments: FileProps | IAttach) => {
    setGuideAttachments((old) => [...old, attachments]);
    formik.setFieldValue("attachments", [
      ...currentGuideAttachments,
      attachments,
    ]);
  };

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    dispatch(getAllWCategories());
  }, []);

  return (
    <>
      <div className={s.formWrap}>
        <Htag tag="h3">
          <GuideIcon /> Add wellbeing guide
        </Htag>
        <form onSubmit={formik.handleSubmit}>
          <div className={s.formField}>
            <Htag tag="h4">Title: </Htag>
            <FormControl fullWidth className={s.title}>
              <input
                id="titleGuide"
                name="title"
                onBlur={formik.handleBlur}
                value={formik.values.title}
                type="text"
                onChange={formik.handleChange}
                placeholder="Title"
              />
              {formik.touched.title && formik.errors.title && (
                <ErrorField error={formik.errors.title} />
              )}
            </FormControl>
          </div>
          <div className={s.formField}>
            <Htag tag="h4">Reading time: </Htag>
            <FormControl className={s.reading_time}>
              <input
                id="reading_timeGuide"
                name="reading_time"
                onBlur={formik.handleBlur}
                value={formik.values.reading_time}
                type="text"
                onChange={formik.handleChange}
                placeholder="Reading time"
              />
              {formik.touched.reading_time && formik.errors.reading_time && (
                <ErrorField error={formik.errors.reading_time} />
              )}
            </FormControl>
          </div>
          {/*    <FormControlLabel
            label="Need a journal"
            className={s.need_journal}
            id="need_jornal"
            control={
              <Checkbox
                name="need_journal"
                id="need_journal"
                checked={formik.values.need_journal}
                onChange={formik.handleChange}
              />
            }
          /> */}
          <div className={s.formField}>
            <Htag tag="h4" className={s.headline}>
              Upload preview image
            </Htag>
            <InputImageComponent
              handleFiles={formik.setFieldValue}
              handleOnBlur={formik.setFieldValue}
              file={formik.values.image}
              id="imageGuide"
              name="image"
            />
          </div>

          <div className={s.formField}>
            <Htag tag="h4" className={s.headline}>
              Upload attachments
            </Htag>
            <InputFileComponent
              files={currentGuideAttachments}
              handleFiles={handleAtachments}
              id="guideFile"
            />
          </div>
          <div className={s.wyswyg_editorWrap}>
            <EditorComponent
              value={formik.values.content}
              handleChange={(e) => formik.setFieldValue("content", e)}
            />

            {formik.touched.content && formik.errors.content && (
              <ErrorField error={formik.errors.content} />
            )}
          </div>
          <div className={s.selectsWrap}>
            <div className={s.select}>
              <Htag tag="h4" className="mb10">
                Connect to divorce step:
              </Htag>
              {
                <TextField
                  id="step_id"
                  name="step_id"
                  label={!formik.values.step_id ? "Task is…" : ""}
                  defaultValue={
                    formik.values.step_id === null ? "" : formik.values.step_id
                  }
                  value={
                    formik.values.step_id === null ? "" : formik.values.step_id
                  }
                  onChange={formik.handleChange}
                  {...commonSelectProps}
                >
                  <MenuItem value={JSON.stringify(null)}>
                    <em>None</em>
                  </MenuItem>
                  {divorceSteps.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              }
            </div>
            <div className={s.select}>
              <Htag tag="h4" className="mb10">
                Choose category:
              </Htag>
              {
                <TextField
                  name="category_id"
                  id="category_id"
                  label={!formik.values.category_id ? "Category is…" : ""}
                  defaultValue={
                    formik.values.category_id === null
                      ? ""
                      : formik.values.category_id
                  }
                  value={
                    formik.values.category_id === null
                      ? ""
                      : formik.values.category_id
                  }
                  onChange={formik.handleChange}
                  {...commonSelectProps}
                >
                  <MenuItem value={JSON.stringify(null)}>
                    <em>None</em>
                  </MenuItem>
                  {categories.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.title}
                    </MenuItem>
                  ))}
                </TextField>
              }
            </div>
          </div>
          <Button apearance="secondary" type="submit">
            Save
          </Button>
        </form>
      </div>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Source is added successfully!
        </Alert>
      </Snackbar>
    </>
  );
};
