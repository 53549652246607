import {
  Checkbox,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import { DeleteIcon, Htag } from "../..";

import { TaskViewProps } from "./TaskView.props";
import s from "./Task.module.css";
import r from "../../ArticleContent/ArticleContent.module.css";
import cn from "classnames";

import ReactHtmlParser from "html-react-parser";

import { downloadAttach } from "../../../controlls/tasks-controll";

import { ITemplates } from "store/tasks/tasks-interface";

import { useAppDispatch, useAppSelector } from "utils/hooks";
import { useNavigate } from "react-router-dom";
import { getArticleLink } from "controlls/use-link";
import { StackItemComponent } from "components/StackItem/StackItem.component";
import { setNewTaskStatus } from "store/divorceSteps/divorceSteps.thunk";

export const TaskItemView = ({
  complete,
  deleteItem,
  task,
}: TaskViewProps): JSX.Element => {
  const boards = useAppSelector((state) => state.tasks.boards);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleSetStatus = (id: number): void => {
    const el = {
      task_id: task.id,
      status: id,
      sort: boards[id].tasks.length,
    };

    dispatch(setNewTaskStatus(el));
  };

  return (
    <div className={s.taskViewWrap}>
      <header className={s.taskViewHeader}>
        <Htag tag="h3">{task.title}</Htag>
      </header>
      <div className={s.task_overview}>
        <div className={cn(s.task_desc_content, r.contentWrap)}>
          {task.description && ReactHtmlParser(task.description)}
        </div>
        {task.guides && task.guides.length > 0 && (
          <div className={s.templates}>
            {task.guides?.map((guide, index) => (
              <StackItemComponent
                key={index}
                onClick={(e) => navigate(getArticleLink("guide", guide.id))}
                className={s.attachmentContainer}
                data={{
                  title: guide.title,
                  type: "guide",
                  reading_time: guide.reading_time,
                }}
              />
            ))}
          </div>
        )}
        {task.attachments && task?.attachments.length > 0 && (
          <div className={s.templates}>
            {task.attachments.map((template: ITemplates, index: number) => (
              <StackItemComponent
                data={{
                  title: template.name || "",
                  type: template.type,
                }}
                onClick={() => {
                  if (!task.id || !template.name) return;

                  downloadAttach(template.id, +task.id, template.name);
                }}
                key={index}
              ></StackItemComponent>
            ))}
          </div>
        )}
      </div>

      <List
        component="nav"
        className={s.bottomNav}
        aria-labelledby="nested-list-subheader"
      >
        {task.status !== 3 && (
          <ListItemButton onClick={() => complete(1)} className={s.taskBtn}>
            <ListItemIcon className={s.navIcon}>
              <Checkbox
                color="default"
                name="terms"
                onChange={(e) => {
                  handleSetStatus(Number(!task.status));
                }}
                checked={!!task.status}
                className={task.status ? s.checkboxChecked : s.unchecked}
              />
            </ListItemIcon>
            <ListItemText primary="Task complete" />
          </ListItemButton>
        )}

        <ListItemButton
          onClick={deleteItem}
          className={cn(s.taskBtn, s.deleteBtn)}
        >
          <ListItemIcon className={s.navIcon}>
            <DeleteIcon />
          </ListItemIcon>
          <ListItemText className={s.deleteText} primary="Delete" />
        </ListItemButton>
      </List>
    </div>
  );
};
