import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ADD_GUIDES_TO_DASHBOARD,
  GET_SINGLE_GUIDE,
  GET_SYSTEM_GUIDES,
  UPDATE_SYSTEM_GUIDE,
} from "../config";
import { IInitState } from "../root-interface";

import { API_TYPE, getRequestFabric } from "../thunk-helper";
import { IGuide } from "./system_guide.interface";

export const add_guides_to_dashboard = createAsyncThunk<
  { success: string },
  string[],
  { getState: IInitState; rejectValue: string }
>(
  "@@systemguides/adding",
  async function (unique_numbers, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.POST,
      getState,
      ADD_GUIDES_TO_DASHBOARD(),
      rejectWithValue,
      dispatch,
      JSON.stringify({ unique_numbers: unique_numbers })
    );
  }
);

export const get_system_guides = createAsyncThunk<
  IGuide[],
  number,
  { getState: IInitState; rejectValue: string }
>(
  "@@systemreguides/getting",
  async function (type, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.GET,
      getState,
      GET_SYSTEM_GUIDES(type),
      rejectWithValue,
      dispatch
    );
  }
);

export const get_single_sguide = createAsyncThunk<
  IGuide,
  string,
  { getState: IInitState; rejectValue: string }
>(
  "@@systemguides/gettingSingleGuide",
  async function (id, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.GET,
      getState,
      GET_SINGLE_GUIDE(Number(id)),
      rejectWithValue,
      dispatch
    );
  }
);

export const updateSystemGuide = createAsyncThunk<
  { success: string; guide: IGuide },
  {
    sg_id: string;
    sg_data: FormData;
  },
  { getState: IInitState; rejectValue: string }
>(
  "@@systemguides/updateGuide",
  async function ({ sg_id, sg_data }, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.POST_DATA,
      getState,
      UPDATE_SYSTEM_GUIDE(sg_id),
      rejectWithValue,
      dispatch,
      sg_data
    );
  }
);
