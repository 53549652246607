import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { resetToDefault } from "../login/login-reducer";

import {
  addNewWCategory,
  addWGuide,
  deleteCategory,
  deleteWGuide,
  loadGuides,
  loadSingleGuide,
  updateCategory,
  updateWGuide,
} from "./wellbeing-thunk";

import { IWellbeingState } from "./wellbeing.interface";

import { loadSingleWSGuide, loadSystemWGuides } from "./wsystem_guides.thunk";
import { getAllWCategories } from "store/categories/categories.thunk";
import { IGuide, PageType } from "store/system_guides/system_guide.interface";

const initChallenges: IWellbeingState = {
  guides: [],
  system_guides: [],
  system_guides_id: [],
  categories: [],
  loading: false,
  error: "",
};

const wellbeingState = createSlice({
  name: "@@wellbeing",
  initialState: initChallenges,
  reducers: {
    addWGuideId: (
      state: IWellbeingState,
      action: PayloadAction<string | string[]>
    ) => {
      if (typeof action.payload == "string") {
        state.system_guides_id.push(action.payload);
      } else {
        const toRemove = new Set(action.payload);
        state.system_guides_id =
          state.system_guides_id.filter((x) => !toRemove.has(x)) || [];
        state.system_guides_id = state.system_guides_id.concat(action.payload);
      }
    },
    removeWGuideId: (
      state: IWellbeingState,
      action: PayloadAction<string | string[]>
    ) => {
      if (typeof action.payload == "string") {
        state.system_guides_id = state.system_guides_id.filter(
          (item) => item !== action.payload
        );
      } else {
        const toRemove = new Set(action.payload);

        state.system_guides_id =
          state.system_guides_id.filter((x) => !toRemove.has(x)) || [];
      }
    },
    resetWGuidesIds: (state) => {
      state.system_guides_id = [];
    },

    saveWBookmark: (state: IWellbeingState, action) => {
      if (action.payload.type == "wguides") {
        state.guides = state.guides.map((item) => {
          if (item.id == action.payload.id) {
            item.bookmark = true;
          }
          return item;
        });
      }
    },
    removeWBookmark: (state: IWellbeingState, action) => {
      if (action.payload.type == "wguides") {
        state.guides = state.guides.map((item) => {
          if (item.id == action.payload.id) {
            item.bookmark = false;
          }
          return item;
        });
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(resetToDefault, () => {
        return initChallenges;
      })
      .addCase(
        getAllWCategories.fulfilled,
        (state: IWellbeingState, action) => {
          state.categories = action.payload;
        }
      )
      .addCase(addNewWCategory.fulfilled, (state: IWellbeingState, action) => {
        state.categories.push();
      })

      .addCase(deleteCategory.fulfilled, (state: IWellbeingState, action) => {
        state.categories = state.categories.filter(
          (ct) => ct.id !== action.payload.category_id
        );
      })
      .addCase(updateCategory.fulfilled, (state: IWellbeingState, action) => {
        state.categories = state.categories.map((x) => {
          if (x.id == action.payload.category.id) {
            return action.payload.category;
          }
          return x;
        });
      })

      .addCase(
        loadGuides.fulfilled,
        (state: IWellbeingState, action: PayloadAction<IGuide[]>) => {
          state.guides = action.payload;
        }
      )
      .addCase(
        loadSystemWGuides.fulfilled,
        (state: IWellbeingState, action: PayloadAction<IGuide[]>) => {
          state.system_guides = action.payload;
        }
      )
      .addCase(loadSingleGuide.fulfilled, (state: IWellbeingState, action) => {
        state.guides = state.guides.filter(
          (guide) => guide.id !== action.payload.id
        );

        state.guides.push(action.payload);
      })
      .addCase(
        loadSingleWSGuide.fulfilled,
        (state: IWellbeingState, action) => {
          state.system_guides = state.system_guides.filter(
            (guide) => guide.id !== action.payload.id
          );

          state.system_guides.push(action.payload);
        }
      )
      .addCase(addWGuide.fulfilled, (state: IWellbeingState, action) => {
        state.guides.push(action.payload.guide);
      })
      .addCase(updateWGuide.fulfilled, (state: IWellbeingState, action) => {
        const guide = { ...action.payload.guide };
        if (guide.unique_number) {
          state.system_guides = state.system_guides.map((x) => {
            if (x.id == action.payload.guide.id) {
              return action.payload.guide;
            }
            return x;
          });
        } else {
          state.guides = state.guides.map((x) => {
            if (x.id == action.payload.guide.id) {
              return action.payload.guide;
            }
            return x;
          });
        }
      })
      .addCase(deleteWGuide.fulfilled, (state: IWellbeingState, action) => {
        state.guides = state.guides.filter((g) => g.id !== action.meta.arg);
      })
      .addMatcher(
        (action) => action.type.endsWith("/pending"),
        (state) => {
          state.loading = true;
          state.error = "";
        }
      )
      .addMatcher(
        (action) => action.type.endsWith("/rejected"),
        (state, action) => {
          state.loading = false;
          if (action.error.message) {
            state.error = action.error.message;
          } else {
            state.error = "Some server error";
          }
        }
      )
      .addMatcher(
        (action) => action.type.endsWith("/fulfilled"),
        (state) => {
          state.loading = false;
          state.error = "";
        }
      );
  },
});

export const {
  saveWBookmark,
  removeWBookmark,
  addWGuideId,
  removeWGuideId,
  resetWGuidesIds,
} = wellbeingState.actions;

export default wellbeingState.reducer;
