export const getArticleLink = (
  type?: string,
  id?: number,
  step_id?: number
) => {
  console.log(type);
  switch (type) {
    case "task":
      return `/dashboard/tasks/${id}`;
    case "special_guide":
    case "guide":
      return `/dashboard/guides/${id}`;
    case "article":
      return `/dashboard/articles/${id}`;
    case "wellbeing_guide":
    case "wguide":
      return `/dashboard/my-wellbeing/guide/${id}`;

    case "case_studies":
      return `/dashboard/case_studies/${id}`;
    default:
      return "";
  }
};
