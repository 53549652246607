import { Box, Modal } from "@mui/material";
import { ModalStyledProps } from "./Modal.props";
import s from "./Modal.module.css";
import { Link } from "react-router-dom";
import cn from "classnames";
import { CloseIcon } from "..";
import { max } from 'date-fns';

const style = {
  boxShadow: 24,
  p: 4,
  transition: "all 0.2s ease",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  maxWidth: "100vw",
};

export const ModalStyled = ({
  children,
  open,
  close,
  size,
  hasTextBelow = false,
  className,
}: ModalStyledProps): JSX.Element => {
  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className={cn(className)}
    >
      <div
        className={cn(s.modalWrap, {
          [s.smallWrap]: size == "s",
          [s.middleWrap]: size == "m",
          [s.largeWrap]: size == "l",
        })}
      >
        <div onClick={close} className={s.close}>
          Close <CloseIcon />
        </div>
        <Box sx={style} className={s.styledBox}>
          {children}
        </Box>
        {hasTextBelow && (
          <p className={s.text_under_box}>
            You can check out our privacy policy{" "}
            <Link reloadDocument to="/privacy-policy" target="_blank">
              <u>here</u>.
            </Link>
          </p>
        )}
      </div>
    </Modal>
  );
};
