import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ADD_TASKS_TO_DASHBOARD,
  GET_TASK_BY_ADMIN,
  GET_TASKS_BY_ADMIN,
  UPDATE_TASK_BY_ADMIN,
} from "../config";
import { IInitState } from "../root-interface";
import { ISystemTask } from "./system_tasks.interface";
import { getRequestFabric, API_TYPE } from "../thunk-helper";

export const add_system_tasks = createAsyncThunk<
  { success: string },
  string[],
  { getState: IInitState; rejectValue: string }
>(
  "@@systemtasks/addingtodashboard",
  async function (unique_numbers, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.POST,
      getState,
      ADD_TASKS_TO_DASHBOARD(),
      rejectWithValue,
      dispatch,
      JSON.stringify({ unique_numbers: unique_numbers })
    );
  }
);

export const get_tasks_by_admin = createAsyncThunk<
  ISystemTask[],
  undefined,
  { getState: IInitState; rejectValue: string }
>(
  "@@systemtasks/getting",
  async function (_, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.GET,
      getState,
      GET_TASKS_BY_ADMIN(),
      rejectWithValue,
      dispatch
    );
  }
);

export const get_task_by_admin = createAsyncThunk<
  ISystemTask,
  number,
  { getState: IInitState; rejectValue: string }
>(
  "@@systemtasks/getSingleTask",
  async function (id, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.GET,
      getState,
      GET_TASK_BY_ADMIN(id),
      rejectWithValue,
      dispatch
    );
  }
);

export const update_task_by_admin = createAsyncThunk<
  { success: string; task: ISystemTask },
  {
    id: string;
    data: {
      title: string;
      description: string;
      step_id?: number;
      attachments: any[];
      reading_time: string;
      guides: string[];
    };
  },
  { getState: IInitState; rejectValue: string }
>(
  "@@systemtasks/updateTask",
  async function ({ id, data }, { getState, rejectWithValue, dispatch }) {
    const formData = new FormData();

    Object.entries({ ...data }).forEach(([key, value]) => {
      if (typeof value == "string") {
        formData.append(key, value);
      } else if (typeof value == "number") {
        formData.append(key, value.toString());
      }
    });
    data.attachments.forEach((file) => {
      formData.append("attachments[][file]", file.file);
      formData.append("attachments[][type]", file.type);
    });
    data.guides.map((guide: string) => {
      formData.append("guides[]", guide);
    });

    return await getRequestFabric(
      API_TYPE.POST_DATA,
      getState,
      UPDATE_TASK_BY_ADMIN(id),
      rejectWithValue,
      dispatch,
      formData
    );
  }
);
